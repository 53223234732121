import { Controller } from "@hotwired/stimulus"
import debounce from "lodash.debounce"

export default class extends Controller {
  static targets = [
    "filterForm",
    "perPageInput"
  ]

  initialize() {
    this.debounceChangeFilters = debounce(() => this.submitFilterForm(), 300)
  }

  changeFilters(e) {
    if (e.target.form == this.filterFormTarget) {
      this.debounceChangeFilters()
    }
  }

  submitFilterForm() {
    this.filterFormTarget.requestSubmit()
  }

  changePerPage(e) {
    const newValue = e.target.value
    if (newValue != this.perPageInputTarget.value) {
      this.perPageInputTarget.value = newValue
      this.perPageInputTarget.removeAttribute("disabled")
      this.submitFilterForm()
    }
  }

  selectionsChanged(e) {
    this.dispatch(
      "selectionsChanged", { detail: e.detail }
    )
  }
}
