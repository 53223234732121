import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ["playerContainer", "playButton", "video", "downloadLink"]

  connect() {
    this.hasMetadataLoaded = false
    this.hasVideoBeenPlayed = false
  }

  onContainerClicked() {
    const video = this.videoTarget

    if (!this.hasMetadataLoaded) {
      return
    }

    if (this.hasMetadataLoaded && !this.hasVideoBeenPlayed) {
      video.play()
      // controls will handle playback from here on out
      video.controls = true

      this.playButtonTarget.classList.add("hidden")
      this.hasVideoBeenPlayed = true
    }
  }

  onLoadedMetadata() {
    // now that we know the width and height, make sure the container has the proper proportions
    const video = this.videoTarget
    const aspectRatio = `${video.videoWidth} / ${video.videoHeight}`

    this.playerContainerTarget.style.aspectRatio = aspectRatio
    this.playerContainerTarget.style.width = `${video.videoWidth}px`
    this.playerContainerTarget.style.height = "auto"

    this.downloadLinkTarget.classList.remove("hidden")

    this.hasMetadataLoaded = true
  }
}
