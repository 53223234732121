import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["accounts", "organizations", "entities"]
  static outlets = ["components--expanded-filter-menu"]

  connect() {
  }

  setScopes(evt) {
    let scopes = JSON.parse(evt.target.dataset.scopes)

    this.componentsExpandedFilterMenuOutlet.setScopes(scopes["account"], scopes["organization"], scopes["entity"])
  }
}
