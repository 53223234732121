import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["edrCheckbox", "siemCheckbox", "saveButton"]

  toggleEdr(event) {
    const checked = event.target.checked

    this.edrCheckboxTargets.forEach((checkbox) => {
      checkbox.checked = checked
    })
  }

  toggleSiem(event) {
    const checked = event.target.checked

    this.siemCheckboxTargets.forEach((checkbox) => {
      checkbox.checked = checked
    })
  }

  handleAcknowledge() {
    if (this.saveButtonTarget.disabled) {
      this.saveButtonTarget.disabled = false
    }
    else {
      this.saveButtonTarget.disabled = true
    }
  }
}
