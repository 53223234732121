import { Controller } from "@hotwired/stimulus"
import moment from "moment"

export default class extends Controller {
  static values = {
    format: String,
    hourFormat: String,
    rangeProfile: String,
    timePickers: Boolean,
    hidePresets: Boolean,
    minDate: String,
    rangeLabel: String
  }

  connect() {
    const format = this.hasFormatValue ? this.formatValue : ""
    const opts = {
      format,

      onbeforeshow: (calentim) => {
        this.range = this.element.value
      },

      onafterhide: (calentim) => {
        if (this.range === this.element.value) {
          return
        }

        calentim.fetchInputs()

        const { startDate, endDate } = calentim.config
        const event = new CustomEvent("components.calentime.changed", {
          detail: { element: this.element, startDate, endDate }
        })

        window.dispatchEvent(event)
      }
    }

    if (this.hasHourFormatValue) {
      opts.hourFormat = this.hourFormatValue
    }

    if (this.hasRangeProfileValue) {
      opts.ranges = this.customRanges()
    }

    if (this.hasTimePickersValue) {
      opts.showTimePickers = this.timePickersValue
    }

    if (this.hasRangeLabelValue) {
      opts.rangeLabel = this.rangeLabelValue
      opts.rangeIcon = ""
    }

    if (this.hasHidePresetsValue) {
      opts.showFooter = !this.hidePresetsValue
    }

    if (this.hasMinDateValue) {
      opts.minDate = this.minDateValue
    }

    $(this.element).calentim(opts)
  }

  customRanges() {
    if (this.rangeProfileValue === "monthly") {
      return [
        {
          title: "Last 2 months",
          startDate: moment().subtract(2, "months"),
          endDate: moment(),
        }, {
          title: "Last 3 months",
          startDate: moment().subtract(3, "months"),
          endDate: moment(),
        }, {
          title: "Last 6 months",
          startDate: moment().subtract(6, "months"),
          endDate: moment()
        }
      ]
    } else if (this.rangeProfileValue === "hourly") {
      return [
        {
          title: "Last 10 minutes",
          startDate: moment().subtract(10, "minutes"),
          endDate: moment(),
        }, {
          title: "Last 30 minutes",
          startDate: moment().subtract(30, "minutes"),
          endDate: moment(),
        }, {
          title: "Last hour",
          startDate: moment().subtract(1, "hours"),
          endDate: moment(),
        }, {
          title: "Last 6 hours",
          startDate: moment().subtract(6, "hours"),
          endDate: moment(),
        }, {
          title: "Last day",
          startDate: moment().subtract(1, "days"),
          endDate: moment()
        },
      ]
    } else if (this.rangeProfileValue === "minutes_to_month") {
      return [
        {
          title: "15 mins",
          startDate: moment().subtract(15, "minutes"),
          endDate: moment(),
        }, {
          title: "30 mins",
          startDate: moment().subtract(30, "minutes"),
          endDate: moment(),
        }, {
          title: "1 hour",
          startDate: moment().subtract(1, "hours"),
          endDate: moment(),
        }, {
          title: "1 day",
          startDate: moment().subtract(1, "days"),
          endDate: moment(),
        }, {
          title: "7 days",
          startDate: moment().subtract(7, "days"),
          endDate: moment()
        },{
          title: "30 days",
          startDate: moment().subtract(30, "days"),
          endDate: moment()
        }
      ]
    } else {
      throw new Error(`unknown range profile value: ${this.rangeProfileValue}`)
    }
  }
}
