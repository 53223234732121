import ApexCharts from "apexcharts"
import { initFlowbite } from "flowbite"
import ChartController from "./chart_controller"

export default class extends ChartController {
  static targets = ["chart", "counter", "dropdown"]

  connect() {
    const chart = this.chartTarget
    const data = JSON.parse(this.data.get("data"))

    const options = {
      colors: ["#69A6FB"],
      series: data,
      chart: {
        sparkline: {
          enabled: false,
        },
        type: "bar",
        height: "350px",
        width: "100%",
        toolbar: {
          show: false,
        }
      },
      fill: {
        opacity: 1,
      },
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: "50%",
          columnWidth: "100%",
          borderRadiusApplication: "end",
          borderRadius: 10,
          dataLabels: {
            position: "top",
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        shared: true,
        intersect: false,
        style: {
          fontFamily: "Inter, sans-serif",
        },
        y: {
          formatter: this.formatTooltip
        }
      },
      states: {
        hover: {
          filter: {
            type: "darken",
            value: 1,
          },
        },
      },
      stroke: {
        show: true,
        width: 0,
        colors: ["transparent"],
      },
      grid: {
        show: false,
        strokeDashArray: 4,
        padding: {
          left: 10,
          right: 2,
          top: -14
        },
      },
      legend: {
        show: true,
        position: "top",
        formatter: (seriesName, opts) => {
          return this.formatLegend(seriesName, opts)
        },
        labels: {
          useSeriesColors: true
        },
        markers: {
          size: 0,
          customHTML: function() {
            return ""
          }
        },
      },
      xaxis: {
        labels: {
          show: true,
          rotate: -45,
          rotateAlways: true,
          style: {
            fontFamily: "Inter, sans-serif",
            cssClass: "!fill-current text-semantics-text-secondary dark:text-semantics-text-secondary-dark text-xs font-normal"
          },
        },
        axisTicks: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
      },
      yaxis: {
        floating: true,
        labels: {
          show: true,
          offsetX: 5,
          align: "left",
          style: {
            fontFamily: "Inter, sans-serif",
            cssClass: "text-xs font-normal fill-gray-500 dark:fill-gray-400"
          }
        }
      },
    }

    this.chartObj = new ApexCharts(chart, options)
    this.chartObj.render()
    initFlowbite()
  }
}
