import { Controller } from "@hotwired/stimulus"
import ApexCharts from "apexcharts"

export default class extends Controller {

  static targets = [
    "linearChart"
  ]

  static values = {
    dataValues: { type: Array },
    colors: { type: Array },
    categories: { type: Array },
    maxY: { type: Number },
    strokeCurve: { type: String}
  }

  connect() {
    const chart = this.linearChartTarget
    const chartOptions = {
      series: this.dataValuesValue,
      chart: {
        height: "100%",
        width: "100%",
        type: "line",
        fontFamily: "Inter, sans-serif",
        dropShadow: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      // visible with 2+ series
      legend: {
        show: true,
        markers: {
          shape: "line",
          strokeWidth: 2,
        }
      },
      colors: this.colorsValue,
      tooltip: {
        enabled: true,
        style: {
          color: "#000"
        },
        x: {
          show: false,
        },
        marker: {
          show: false
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: this.strokeCurveValue,
        width: 2,
      },
      grid: {
        show: true,
        padding: {
          left: 16,
          right: 16,
          top: -16
        },
        xaxis: {
          lines: {
            show: true
          },
        },
      },
      markers: {
        size: 4,
        shape: "circle",
        strokeWidth: 0,
        hover: {
          sizeOffset: 3
        },
      },
      xaxis: {
        tooltip: { enabled: false },
        categories: this.categoriesValue,
        labels: {
          show: true,
          rotate: -45,
          rotateAlways: true,
          style: {
            fontFamily: "Inter, sans-serif",
            cssClass: "!fill-current text-semantics-text-primary dark:text-semantics-text-primary-dark text-xs font-normal"
          },
          hideOverlappingLabels: true,
          showDuplicates: false,
          datetimeUTC: true,
          format: "MM-dd",
        },
        axisBorder: {
          show: true,
        },
        axisTicks: {
          show: true,
        },
      },
      yaxis: {
        min: 0,
        max: this.maxYValue,
        stepSize: 10,
        labels: {
          style: {
            fontFamily: "Inter, sans-serif",
            cssClass: "!fill-current text-semantics-text-primary dark:text-semantics-text-primary-dark text-xs font-normal"
          },
        }
      },
    }

    this.chart = new ApexCharts(chart, chartOptions)
    this.chart.render()
  }

  disconnect() {
    this.chart.destroy()
  }
}
