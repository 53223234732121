import ApexCharts from "apexcharts"
import { initFlowbite } from "flowbite"
import ChartController from "./chart_controller"

export default class extends ChartController {
  static targets = ["chart", "counter", "dropdown"]

  connect() {
    const chart = this.chartTarget
    const data = JSON.parse(this.data.get("data"))

    const options = {
      colors: ["#FB923C", "#EF4444", "#A25906"],
      series: data,
      chart: {
        height: "350px",
        type: "bar",
        fontFamily: "Inter, sans-serif",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "70%",
          borderRadiusApplication: "end",
          borderRadius: 8,
        },
      },
      tooltip: {
        shared: true,
        intersect: false,
        style: {
          fontFamily: "Inter, sans-serif",
        },
        y: {
          formatter: this.formatTooltip
        }
      },
      states: {
        hover: {
          filter: {
            type: "darken",
            value: 1,
          },
        },
      },
      stroke: {
        show: true,
        width: 0,
        colors: ["transparent"],
      },
      grid: {
        show: false,
        strokeDashArray: 4,
        padding: {
          left: 2,
          right: 2,
          top: -14
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: true,
        position: "top",
        formatter: (seriesName, opts) => {
          return this.formatLegend(seriesName, opts)
        },
        labels: {
          useSeriesColors: true
        },
        markers: {
          size: 0,
          customHTML: function() {
            return ""
          }
        },
      },
      xaxis: {
        floating: false,
        labels: {
          show: true,
          style: {
            fontFamily: "Inter, sans-serif",
            cssClass: "text-xs font-normal fill-gray-500 dark:fill-gray-400"
          },
          hideOverlappingLabels: true,
          showDuplicates: false,
          datetimeUTC: true,
          format: "MMM dd",
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        type: "datetime",
      },
      yaxis: {
        show: false,
      },
      fill: {
        opacity: 1,
      },
    }

    this.chartObj = new ApexCharts(chart, options)
    this.chartObj.render()
    // Default View
    this.setDateRange(7)
    initFlowbite()
  }
}
