import { Controller } from "@hotwired/stimulus"
import * as Routes from "routes"

export default class extends Controller {
  static targets = ["remediationCount"]

  static values = {
    invalidIps: Object
  }

  connect() {
    this.#clearPreviousBootstrapModals()

    // Legacy Connects
    this.#populateSelectableAnalystsInModal()
    this.#overrideModalLinkClickAction()
  }

  warnEventSummaryChange(e) {
    if (window.confirm("Confirm Change\n\nSelecting a new signal will overwrite any previously-modified investigative summary.")) {
      return
    }

    e.stopPropagation()
    e.preventDefault()
  }

  #populateSelectableAnalystsInModal() {
    $("#infection_report_assigned_to_id")
      .select2({
        theme: "bootstrap",
        ajax: {
          url: Routes.select2_admin_users_path("json", { roles: ["admin", "analyst", "analyst_admin", "customer_success"] }),
          dataType: "json",
          delay: 250
        },
        width: "100%"
      })
  }

  #overrideModalLinkClickAction() {
    $(document).on("click", ".modal-link", function(e) {
      e.preventDefault()
      $.ajax({
        url: $(this).attr("href"),
        dataType: "json",
        success: function(data) {
          $("#details-modal .modal-dialog").html(data.content)
          $("#details-modal").modal("show")
        },
        error: function(err) {
          console.log(err)
        }
      })
    })
  }

  #clearPreviousBootstrapModals() {
    document.querySelectorAll(".modal-backdrop").forEach(element => element.remove())
    document.body.classList.remove("modal-open")
  }

  #debounce(callback) {
    if(this.debounceTimeout) {
      clearTimeout(this.debounceTimeout)
    }
    this.debounceTimeout = setTimeout(callback, 250)
  }
}
