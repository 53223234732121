import { Controller } from "@hotwired/stimulus"
import * as Routes from "routes"

export default class extends Controller {
  static targets = ["accounts", "organizations", "entities"]

  connect() {
    this.account_ids = JSON.parse(this.element.dataset["accountIds"]) || []
    this.organization_ids = JSON.parse(this.element.dataset["organizationIds"]) || []
    this.showAccount = this.hasAccountsTarget
    this.showOrganization = this.hasOrganizationsTarget

    this.defaultSelect2Options = {
      theme: "bootstrap",
      multiple: true,
      width: "resolve",
      closeOnSelect: false,
      allowClear: true,
    }

    this.initializeAll()

    if (this.hasAccountsTarget) {
      $(this.accountsTarget).on("select2:close", this.initializeAll.bind(this))
    }

    if (this.hasOrganizationsTarget) {
      $(this.organizationsTarget).on("select2:close", this.initializeAll.bind(this))
    }

    $(this.entitiesTarget).on("select2:close", this.initializeAll.bind(this))
  }

  initializeAll() {
    this.initializeAccounts()
    this.initializeOrganizations()
    this.initializeEntities()
  }

  initializeAccounts(){
    if (!this.showAccount) {
      return
    }

    $(this.accountsTarget).select2(Object.assign({
      placeholder: "Select Accounts",
      ajax: {
        url: Routes.select2_admin_accounts_path(),
        dataType: "json",
        delay: 150,
        processResults: function (data) {
          const items = data.results.map(item => { item.text += ` (${item.subdomain})`; return item })
          return { results: items }
        }
      }
    }, this.defaultSelect2Options))

    if (this.accounts.length === 0) {
      if (this.hasOrganizationsTarget) {
        $(this.organizationsTarget).val("")
      }

      $(this.entitiesTarget).val("")
    }
  }

  initializeOrganizations(){
    if (!this.showOrganization) {
      return
    }

    if (this.accounts && this.accounts.length === 1) {
      this.organizationsTarget.removeAttribute("disabled")

      const url = this.showAccount ? Routes.select2_admin_organizations_path({accounts: this.accounts}) : Routes.select2_account_signals_path()
      $(this.organizationsTarget).select2(Object.assign({
        placeholder: "Select Organizations",
        ajax: {
          url: url,
          dataType: "json",
          delay: 150
        }
      }, this.defaultSelect2Options))
    } else {
      this.organizationsTarget.setAttribute("disabled", true)
      $(this.organizationsTarget).select2(Object.assign({ placeholder: "Select Organizations" }, this.defaultSelect2Options))
    }

    if (this.organizations.length === 0) {
      $(this.entitiesTarget).val("")
    }
  }

  initializeEntities(){
    if (this.organizations && this.organizations.length === 1) {
      this.entitiesTarget.removeAttribute("disabled")

      const url = this.showAccount ? Routes.entities_select2_admin_signals_path({ organizations: this.organizations }) : Routes.entities_select2_organization_signals_path(this.organizations[0])
      $(this.entitiesTarget).select2(Object.assign({
        placeholder: "Select Entity",
        ajax: {
          url: url,
          dataType: "json",
          delay: 150
        }
      }, this.defaultSelect2Options))
    } else {
      this.entitiesTarget.setAttribute("disabled", true)
      $(this.entitiesTarget).select2(Object.assign({ placeholder: "Select Entity" }, this.defaultSelect2Options))
    }
  }

  setScopes(account, organization, entity) {
    if (this.hasAccountsTarget) {
      let accountSelect = $(this.accountsTarget)
      accountSelect.val("")

      if (account) {
        this.selectScope(accountSelect, account)
      }

      accountSelect.trigger("change")
    }

    if (this.hasOrganizationsTarget) {
      let organizationSelect = $(this.organizationsTarget)
      organizationSelect.val("")

      if (organization) {
        this.selectScope(organizationSelect, organization)
      }

      organizationSelect.trigger("change")
    }

    if (this.hasEntitiesTarget) {
      let entitySelect = $(this.entitiesTarget)
      entitySelect.val("")

      if (entity) {
        this.selectScope(entitySelect, entity)
      }

      entitySelect.trigger("change")
    }

    this.initializeAll()
  }

  selectScope(target, scope) {
    if (target.find("option[value='" + scope["value"] + "']").length) {
      target.val(scope["value"])
    } else {
      let option = new Option(scope["name"], scope["value"], false, true)
      target.append(option)
    }
  }

  get accounts() {
    if (this.hasAccountsTarget) {
      return [].concat($(this.accountsTarget).select2("data")).map((option) => option["id"])
    } else {
      return this.account_ids
    }
  }

  get organizations() {
    if (this.hasOrganizationsTarget) {
      return [].concat($(this.organizationsTarget).select2("data")).map((option) => option["id"])
    } else {
      return this.organization_ids
    }
  }

  get entities() {
    return [].concat($(this.entitiesTarget).select2("data")).map((option) => option["id"])
  }
}
