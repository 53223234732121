import { Controller } from "@hotwired/stimulus"
import debounce from "lodash.debounce"

export default class extends Controller {
  static targets = ["searchInput"]

  initialize() {
    this.debounceSubmit = debounce(() => {
      this.searchInputTarget.requestSubmit()
    }, 300)
  }

  submitSearch(e) {
    if (e.target.form == this.searchInputTarget) {
      this.debounceSubmit()
    }
  }
}
