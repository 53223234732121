import DialogController from "./dialog_controller"

const MIN_TRANSITION_SPEED = 300
const MAX_TRANSITION_SPEED = 500

const BACKDROP_TRANSITION = `ease-in-out duration-${MAX_TRANSITION_SPEED}`
const BACKDROP_HIDE = "opacity-0"
const BACKDROP_SHOW = "opacity-100"

const PANEL_TRANSITION = `transform transition ease-in-out duration-${MIN_TRANSITION_SPEED} sm:duration-${MAX_TRANSITION_SPEED}`
const PANEL_HIDE = "translate-x-full"
const PANEL_SHOW = "translate-x-0"

export default class extends DialogController {
  transitionBackdropIn() {
    this.applyTransition(this.backdropTarget, {
      enter: BACKDROP_TRANSITION,
      enterFrom: BACKDROP_HIDE,
      enterTo: BACKDROP_SHOW,
    })
  }

  transitionPanelIn() {
    // panel transitions
    this.applyTransition(this.panelTarget, {
      enter: PANEL_TRANSITION,
      enterFrom: PANEL_HIDE,
      enterTo: PANEL_SHOW,
    })
  }

  transitionBackdropOut() {
    this.applyTransition(this.backdropTarget, {
      leave: BACKDROP_TRANSITION,
      leaveFrom: BACKDROP_SHOW,
      leaveTo: BACKDROP_HIDE,
    })
  }

  transitionPanelOut() {
    this.applyTransition(this.panelTarget, {
      leave: PANEL_TRANSITION,
      leaveFrom: PANEL_SHOW,
      leaveTo: PANEL_HIDE,
    })
  }

  hideDialog() {
    setTimeout(() => {
      if (!this.openValue) {
        this.dialogTarget.classList.add("hidden")
      }
    }, MAX_TRANSITION_SPEED) // match longest duration (sm:duration-MAX_DURATION_SPEED)
  }

  clickSafeZone() {
    return this.panelTarget
  }
}
