import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["learners", "recipients", "description", "descriptionPreview"]

  connect() {
    if (!this.learnersTarget.disabled) {
      this.learnersTarget.disabled = this.recipientsTarget.checked
    }
    this.descriptionPreviewTarget.textContent = this.descriptionTarget.textContent
  }

  toggleLearnersField(event) {
    this.learnersTarget.disabled = event.target.value === "true"
  }

  updateDescriptionPreview(event) {
    this.descriptionPreviewTarget.textContent = event.target.value
  }
}
