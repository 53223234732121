import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "button", "bulkSelectionInput" ]
  static values = {
    paramName: { type: String, default: "ids" },
    inputMode: { type: String, default: "array" }
  }

  updateSelections(e) {
    if (!e.target.contains(this.element)) return

    const values = e.detail.values
    this.buttonTarget.toggleAttribute("disabled", values.length == 0)
    this.#updateInputs(values)
  }

  #updateInputs(values) {
    for(const input of this.bulkSelectionInputTargets) {
      input.remove()
    }

    if (!values.length) return

    if (this.inputModeValue == "join") {
      this.#writeInput(this.paramNameValue, values.join(","))
    } else {
      for (const value of values) {
        this.#writeInput(`${this.paramNameValue}[]`, value)
      }
    }
  }

  #writeInput(inputName, value) {
    const input = document.createElement("input")
    input.type = "hidden"
    input.setAttribute("data-ui--bulk-action-target", "bulkSelectionInput")
    input.name = inputName
    input.value = value
    this.element.appendChild(input)
  }
}
