import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static chartObj
  static animating = false
  animationSpeed = 150

  handleDateRange(e) {
    const target = $(e.target)
    const numDays = parseInt(target.data("days"))
    this.setDateRange(numDays)
    this.dropdownTarget.childNodes[0].nodeValue = target.text()
  }

  setDateRange(numDays){
    const startDate = new Date()
    startDate.setDate(startDate.getDate() - numDays + 1)
    startDate.setUTCHours(0,0,0,0)
    const endDate = new Date()
    endDate.setUTCHours(0,0,0,0)
    this.chartObj.zoomX(startDate.getTime(), endDate.getTime())
  }

  animateCounter() {
    this.animating = true
    const value = parseInt(this.counterTarget.getAttribute("value"))
    const data = parseInt(this.counterTarget.innerText.replace(/,/g, ""))
    const time = Math.abs((value - data) / this.animationSpeed)

    if(data > value){
      this.animateDown(time)
    }
    else{
      this.animateUp(time)
    }
  }

  animateUp(time){
    const value = parseInt(this.counterTarget.getAttribute("value"))
    const data = parseInt(this.counterTarget.innerText.replace(/,/g, ""))

    if(data < value) {
      this.counterTarget.innerText = this.numberWithCommas(Math.ceil(data + time))
      setTimeout(() => {
        this.animateUp(time)
      }, 1)
    }
    else{
      this.counterTarget.innerText = this.numberWithCommas(value)
      this.animating = false
    }
  }

  animateDown(time){
    const value = parseInt(this.counterTarget.getAttribute("value"))
    const data = parseInt(this.counterTarget.innerText.replace(/,/g, ""))

    if(data > value) {
      this.counterTarget.innerText =  this.numberWithCommas(Math.ceil(data - time))
      setTimeout(() => {
        this.animateDown(time)
      }, 1)
    }
    else{
      this.counterTarget.innerText = this.numberWithCommas(value)
      this.animating = false
    }
  }

  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }

  formatTooltip(value, { series, seriesIndex, dataPointIndex, w }) {
    if(dataPointIndex > 0){
      let pv = series[seriesIndex][dataPointIndex - 1]
      let percent_change = (100 * (value - pv)/pv).toFixed(2)
      percent_change = percent_change > 0 ? `+${percent_change}`: percent_change
      return `${value} ${percent_change}%`
    }
    else{
      return value
    }
  }

  formatLegend(seriesName, opts) {
    const lastVal = opts.w.globals.initialSeries[opts.seriesIndex].data.at(0).y
    let total = 0
    for(let series of opts.w.globals.series){
      if(series.at(0))
        total += series.at(0)
    }
    this.counterTarget.setAttribute("value", total)
    if(!this.animating)
      this.animateCounter()
    if(opts.w.globals.collapsedSeriesIndices.includes(opts.seriesIndex))
      return `
      <div class="flex flex-row gap-1 disabled text-sm font-normal me-1">
        <svg style="width: 1em; height: 1em; vertical-align: -0.125em;" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
          <path d="M384 80c8.8 0 16 7.2 16 16l0 320c0 8.8-7.2 16-16 16L64 432c-8.8 0-16-7.2-16-16L48 96c0-8.8 7.2-16 16-16l320 0zM64 32C28.7 32 0 60.7 0 96L0 416c0 35.3 28.7 64 64 64l320 0c35.3 0 64-28.7 64-64l0-320c0-35.3-28.7-64-64-64L64 32z"/>
        </svg>
        <span>${seriesName}:</span> <span class="font-semibold">${lastVal}</span>
      </div>
    `
    else
      return `
        <div class="flex flex-row gap-1 disabled text-sm font-normal me-1">
          <svg style="width: 1em; height: 1em; vertical-align: -0.125em;" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
            <path d="M400 480H48c-26.5 0-48-21.5-48-48V80c0-26.5 21.5-48 48-48h352c26.5 0 48 21.5 48 48v352c0 26.5-21.5 48-48 48zm-204.7-98.1l184-184c6.2-6.2 6.2-16.4 0-22.6l-22.6-22.6c-6.2-6.2-16.4-6.2-22.6 0L184 302.7l-70.1-70.1c-6.2-6.2-16.4-6.2-22.6 0l-22.6 22.6c-6.2 6.2-6.2 16.4 0 22.6l104 104c6.2 6.3 16.4 6.3 22.6 0z"/>
          </svg>
          <span class="text-sm font-normal me-1">${seriesName}:</span> <span class="font-semibold">${lastVal}</span>
        </div>
      `
  }
}
