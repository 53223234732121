import { Controller } from "@hotwired/stimulus"
import { Popover } from "flowbite"

export default class extends Controller {
  static targets = [
    "popover",
    "popoverTrigger"
  ]

  static values = {
    showOn: { type: String, default: "click" },
    placement: { type: String, default: "bottom" }
  }

  connect() {
    this.popover = new Popover(
      this.popoverTarget,
      this.popoverTriggerTarget,
      { placement: this.placementValue, triggerType: this.showOnValue }
    )
  }

  disconnect() {
    this.popover.destroy()
  }
}
