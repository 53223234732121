import { Controller } from "@hotwired/stimulus"
import * as Routes from "routes"
import { getSelectedIds } from "../../../helpers/bulkActionHelpers"

export default class extends Controller {
  static targets = [ "bulkReportLink" ]

  connect() {
    $("#datatable table")
      .on("init.dt", function() {
        $("#loading").hide()
        $("#datatable").fadeIn()
      })

    $("#datatable table").dataTable({
      autoWidth: false,
      destroy: true,
      processing: true,
      iDisplayLength: 25,
      lengthMenu: [10, 25, 50, 100, 200, 500],
      stateSave: true,
      stateDuration: 300,
      serverSide: true,
      columnDefs: [
        { targets: "_all", searchable: false },
      ],
      order: [[3, "desc"]],
      searching: true,
      deferRender: true,
      responsive: true,
      ajax: {
        url: Routes.admin_antivirus_detections_path({format: "json"}),
        data: { filters: JSON.parse(this.element.dataset.filters) },
        dataType: "json",
        type: "GET"
      },
    })
  }

  updateBulkReportLink() {
    if (!this.hasBulkReportLinkTarget) {
      return
    }

    const target = this.bulkReportLinkTarget
    const url = new URL(target.dataset.url, window.location.origin)
    const ids = getSelectedIds(target.dataset.scope).split(", ")
    ids.forEach(id => url.searchParams.append("ids[]", id))

    target.href = url.toString()
  }
}
