import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  trigger() {
    const dialogId = this.element.dataset.dialogTarget
    const event = new CustomEvent("ui:dialog:open", { detail: { id: dialogId } })

    document.dispatchEvent(event)
  }
}
