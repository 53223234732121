import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "reportsDateRangeField",
    "reportTypeSelect"
  ]

  static values = {
    customReportString: String
  }

  connect() {
    this.selectValueChanged()
  }

  selectValueChanged() {
    this.reportsDateRangeFieldTarget.classList.toggle("invisible", this.reportTypeSelectTarget.value != this.customReportStringValue)
  }
}
