import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "entitySelect",
    "entitySelectContainer",
    "featurableId",
    "submit"
  ]

  connect() {
    this.initializeSelect2()
    this.registerListeners()
    this.setSubmitState()
  }

  disconnect() {
    this.removeSelect2()
    this.removeListeners()
  }


  entityAjaxData(params) {
    return { term: params.term, page: params.page, organization_id: this.organizationId }
  }

  clearEntitySelection() {
    $(this.entitySelectTarget).val(null).trigger("change")
    this.featurableIdTarget.setAttribute("value", null)
    this.setSubmitState()
  }

  fieldValid(val) {
    return val && val.length > 0
  }

  initializeEntitySelect2() {
    const placeholder = this.entitySelectTarget.dataset.placeholder
    const url = this.entitySelectTarget.dataset.url
    $(this.entitySelectTarget).select2({
      placeholder: placeholder,
      theme: "bootstrap",
      allowClear: true,
      width: "100%",
      cache: true,
      ajax: {
        url: url,
        dataType: "json",
        data: this.entityAjaxData.bind(this),
        delay: 150,
      },
    })
  }

  onEntitySelect() {
    this.featurableIdTarget.setAttribute("value", this.entityId)
    this.setSubmitState()
  }

  setSubmitState() {
    if (this.formValid) {
      this.submitTarget.disabled = false
    } else {
      this.submitTarget.disabled = true
    }
  }

  get entityId() {
    let selectedData = $(this.entitySelectTarget).select2("data")
    return selectedData.length > 0 ? selectedData[0].id : null
  }

  get formValid() {
    return this.fieldValid(this.entityId)    
  }
}
