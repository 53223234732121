import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ["agentInstructionsDrawer", "extensionInstructionsDrawer"]

  // Called when the user clicks a link in the agent instructions drawer to
  // close that drawer and open the extension instructions drawer
  showExtensionInstallationInstructions() {
    $(this.agentInstructionsDrawerTarget).modal("hide")
    $(this.extensionInstructionsDrawerTarget).modal("show")
  }
}
