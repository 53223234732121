import { Controller } from "@hotwired/stimulus"
import { DateRangePicker } from "flowbite-datepicker"

export default class extends Controller {
  connect() {
    this.datepicker = new DateRangePicker(
      this.element,
      {
        format: "yyyy-mm-dd",
      }
    )
    this.datepicker.datepickers.forEach((picker) => {
      picker.pickerElement.classList.add("tw")
    })
  }

  disconnect() {
    this.datepicker.destroy()
  }
}
