import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets= [
    "selectExclusionSetting",
    "radioRemoveExclusion",
    "radioAllowExclusion",
    "datatable"
  ]

  initialize() {
    this.submitting = false
  }

  connect() {
    $(this.selectExclusionSettingTarget).on("select2:select", function (e) {
      this.onPresetSelect(e.target.value)
    }.bind(this))
    this.setupDataTable()
  }

  confirmLeave(event) {
    if (!this.submitting) {
      event.preventDefault()
      event.returnValue = ""
    }
  }

  submit() {
    this.submitting = true
  }

  setupDataTable(){
    $(this.datatableTarget).DataTable({
      processing: true,
      stateSave: true,
      stateDuration: 300,
      searching: true,
      paging: false,
      columnDefs: [
        { searchable: false, targets: 0 },
        { searchable: false, targets: 0 },
        { searchable: true, targets: 0 },
        { searchable: false, targets: 0 },
        { searchable: false, targets: 0 },
      ]
    })
  }

  onPresetSelect(mode) {
    if (mode == "remove") {
      this.radioRemoveExclusionTargets.forEach((radioButton) => radioButton.checked = true)
    } else if (mode == "allow") {
      this.radioAllowExclusionTargets.forEach((radioButton) => radioButton.checked = true)
    }
  }

  onClickRadioButton(e) {
    $(this.selectExclusionSettingTarget).val("custom")
    $(this.selectExclusionSettingTarget).trigger("change")
  }
}
