import { Controller } from "@hotwired/stimulus"
import { flashMessage } from "huntressHelpers"
import * as Routes from "routes"

export default class extends Controller {
  static targets = ["taskGroupNameInput", "accountsInput", "organizationsInput", "hostsInput", "tasksTypeSummary", "taskGroupNameSummary",
    "accountsSummary", "organizationsSummary", "hostsSummary", "template", "popover", "newTaskForm" ]

  connect() {
    $(this.popoverTarget).popover({
      placement: "bottom",
      template: this.templateTarget.innerHTML,
      toggle: "popover",
      trigger: "focus",
    })

    this.accountsInputSelect2("Accounts...")
    this.organizationsInputSelect2("Organizations...")
    this.hostsInputSelect2("Hosts...")
    this.prefillForm()

    $(this.accountsInputTarget).on("change", async (e)=> {
      let accounts = this.getSelectValues(e.target)
      this.updateAccountsSummary()
      if(accounts.length == 1){
        this.organizationsInputTarget.removeAttribute("readonly")
        this.organizationsInputSelect2("Organizations...")
      }
      else if(accounts.length >= 2){
        this.organizationsInputTarget.setAttribute("readonly", true)
        let options = this.organizationsAjaxOptions
        options.data = {
          accounts: this.getSelectValues(this.accountsInputTarget)
        }
        let orgs = await this.organizationsAjax(options)
        this.organizationsInputSelect2(`${orgs.results.length} Organizations`)

      }
      else {
        this.organizationsInputTarget.setAttribute("readonly", true)
      }
      this.resetSelect(this.organizationsInputTarget)
    })

    $(this.organizationsInputTarget).on("change", async(e) => {
      let options = this.hostsAjaxOptions
      let selected_accounts = this.getSelectValues(this.accountsInputTarget)
      let selected_orgs = this.getSelectValues(this.organizationsInputTarget)
      if(selected_orgs.length > 0){
        options.data = {
          organizations: selected_orgs
        }
      }
      else if(selected_accounts.length > 0){
        options.data = {
          accounts: selected_accounts
        }
      }
      else{
        this.updateHostsSummary(0)
        return this.hostsInputSelect2("Hosts...")
      }
      this.updateOrganizationsSummary()
      let hosts = await this.hostsAjax(options)
      this.hostsInputSelect2(`${hosts.results.length} Hosts`)
      this.updateHostsSummary(hosts.results.length)
    })
  }

  submitForm(e){
    if (this.ajaxFormPending)
      return
    this.ajaxFormPending = true
    $.ajax({
      type: "POST",
      url: $(this.newTaskFormTarget).attr("action"),
      data: $(this.newTaskFormTarget).serialize(),
      success: (response) => {
        location.href = Routes.admin_tasks_path()
      },
      error: (jqXHR, textStatus, error ) => {
        flashMessage(jqXHR.responseJSON.error, "danger", $(".tasks").parents().eq(0)[0])
      },
      complete: () => {
        this.ajaxFormPending = false
      }
    })
  }

  prefillForm(){
    if(!this.data.has("prefilled"))
      return
    let prefilled = JSON.parse(this.data.get("prefilled"))
    let accounts = []
    let organizations = []
    let agents = []

    for(let account_id in prefilled.accounts){
      accounts.push(account_id)
      $(this.accountsInputTarget).append(new Option(prefilled.accounts[account_id], account_id, false, false))
    }

    for(let organization_id in prefilled.organizations){
      organizations.push(organization_id)
      $(this.organizationsInputTarget).append(new Option(prefilled.organizations[organization_id], organization_id, false, false))
    }

    for(let agent_id in prefilled.agents){
      agents.push(agent_id)
      $(this.hostsInputTarget).append(new Option(prefilled.agents[agent_id], agent_id, false, false))
    }

    $(this.accountsInputTarget).val(accounts).trigger("change")
    $(this.organizationsInputTarget).val(organizations).trigger("change")
    $(this.hostsInputTarget).val(agents).trigger("change")

    this.accountsInputTarget.setAttribute("readonly", true)
    this.organizationsInputTarget.setAttribute("readonly", true)
    this.hostsInputTarget.setAttribute("readonly", true)

    this.updateAccountsSummary()
    this.updateOrganizationsSummary()
    this.updateHostsSummary(agents.length)
  }

  taskGroupNameBlur(e){
    this.taskGroupNameSummaryTarget.textContent = e.currentTarget.value
  }

  updateAccountsSummary(){
    let accounts = this.getSelectValues(this.accountsInputTarget, "text")
    this.accountsSummaryTarget.innerHTML = ""
    if(accounts.length < 10){
      for(let account of accounts){
        this.accountsSummaryTarget.innerHTML += `<div>${account}</div>`
      }
    }
    else{
      this.accountsSummaryTarget.innerHTML = `${accounts.length} Accounts`
    }
  }

  updateOrganizationsSummary(){
    let orgs = this.getSelectValues(this.organizationsInputTarget, "text")
    this.organizationsSummaryTarget.innerHTML = ""
    if(orgs.length < 10){
      for(let org of orgs){
        this.organizationsSummaryTarget.innerHTML += `<div>${org}</div>`
      }
    }
    else{
      this.organizationsSummaryTarget.innerHTML = `${orgs.length} Organizations`
    }
  }

  updateHostsSummary(hosts){
    this.hostsSummaryTarget.innerHTML = `${hosts} Host(s)`
  }

  accountsInputSelect2(placeholder){
    $(this.accountsInputTarget).select2({
      placeholder: placeholder,
      theme: "bootstrap",
      width: "100%",
      multiple: true,
      ajax: {
        url: Routes.select2_admin_accounts_path(),
        dataType: "json",
        delay: 150
      }
    })
  }

  organizationsInputSelect2(placeholder){
    $(this.organizationsInputTarget).select2({
      placeholder: placeholder,
      theme: "bootstrap",
      width: "100%",
      multiple: true,
      ajax: this.organizationsAjaxOptions
    })
  }

  hostsInputSelect2(placeholder){
    $(this.hostsInputTarget).select2({
      placeholder: placeholder,
      theme: "bootstrap",
      width: "100%",
      multiple: true,
      ajax: this.hostsAjaxOptions
    })
  }

  getSelectValues(select, mode = "value") {
    let result = []
    let options = select && select.options
    let opt

    for (var i=0, iLen=options.length; i<iLen; i++) {
      opt = options[i]

      if (opt.selected && opt.value) {
        result.push(opt[mode])
      }
    }
    return result
  }

  resetSelect(select){
    $(select).empty().trigger("change")
  }

  async organizationsAjax(options){
    return new Promise((resolve, reject) => {
      if(this.organizationsAjaxRequest)
        this.organizationsAjaxRequest.abort()
      if(!options.success){
        options.success = (response) => {
          return resolve(response)
        }
      }
      if(!options.failure){
        options.error = (jqXHR, textStatus, error ) => {
          return reject(error)
        }
      }
      this.organizationsAjaxRequest = $.ajax(options)
    })
  }

  async hostsAjax(options){
    return new Promise((resolve, reject) => {
      if(this.hostsAjaxRequest)
        this.hostsAjaxRequest.abort()
      if(!options.success){
        options.success = (response) => {
          return resolve(response)
        }
      }
      if(!options.failure){
        options.error = (jqXHR, textStatus, error ) => {
          return reject(error)
        }
      }
      this.hostsAjaxRequest = $.ajax(options)
    })
  }

  get organizationsAjaxOptions(){
    return {
      url: Routes.select2_admin_organizations_path(),
      dataType: "json",
      delay: 150,
      contentType: "application/json",
      data: (q, page) => {
        let params = {
          accounts: this.getSelectValues(this.accountsInputTarget)
        }
        if(q.term)
          params.q = q.term
        return params
      }
    }
  }

  get hostsAjaxOptions(){
    return {
      url: Routes.select2_admin_agents_path(),
      dataType: "json",
      delay: 150,
      contentType: "application/json",
      data: (q, page) => {
        let params = {
          organizations: this.getSelectValues(this.organizationsInputTarget)
        }
        if(q.term)
          params.q = q.term
        return params
      }
    }
  }

}
