import ApexCharts from "apexcharts"
import { initFlowbite } from "flowbite"
import ChartController from "./chart_controller"

export default class extends ChartController {
  static targets = ["chart", "dropdown"]

  connect() {
    const chart = this.chartTarget
    const data = JSON.parse(this.data.get("data"))
    const colors = JSON.parse(this.data.get("colors"))

    const options = {
      colors: colors,
      chart: {
        height: "300px",
        type: "area",
        fontFamily: "Inter, sans-serif",
        dropShadow: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
        zoom: {
          autoScaleYaxis: true
        }
      },
      tooltip: {
        enabled: true,
        x: {
          show: false,
        },
        y: {
          formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
            if(dataPointIndex > 0){
              let formatted_value = new Intl.NumberFormat("en-US").format(value)
              let pv = series[seriesIndex][dataPointIndex - 1]
              let percent_change = (100 * (value - pv)/pv).toFixed(2)
              percent_change = percent_change > 0 ? `+${percent_change}`: percent_change
              return `${formatted_value} ${percent_change}%`
            }
            else{
              return value
            }
          }
        }
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 6,
        curve: "smooth"
      },
      grid: {
        show: true,
        strokeDashArray: 4,
        padding: {
          left: 20,
          right: 20,
          top: -26
        },
      },
      series: data,
      legend: {
        show: false
      },
      xaxis: {
        labels: {
          show: true,
          style: {
            fontFamily: "Inter, sans-serif",
            cssClass: "!fill-current text-semantics-text-secondary dark:text-semantics-text-secondary-dark text-xs font-normal"
          },
          hideOverlappingLabels: true,
          showDuplicates: false,
          datetimeUTC: true,
          format: "MMM dd ",
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        type: "datetime",
      },
      yaxis: {
        show: false,
      },
    }

    this.chartObj = new ApexCharts(chart, options)
    this.chartObj.render()

    this.setDateRange(7)
    initFlowbite()
  }
}
