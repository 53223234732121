import { Controller } from "@hotwired/stimulus"

// Example Usage
// %div{ data: { controller: "components--modal-redirect", "components--modal-redirect-url" => my_fancy_path } }

export default class extends Controller {

  connect() {
    window.location = this.data.get("url")
  }
}
