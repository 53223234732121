import TableController from "./table_controller.js"
import { initTableWithChildren, attachModalLinkEvents } from "huntressHelpers"
import { initFlowbite } from "flowbite"

export default class extends TableController {

  connect() {
    const dataUrl = this.data.get("url")
    const headers = JSON.parse(this.data.get("headers"))
    const queryData = JSON.parse(this.data.get("queryData"))

    const orderingEnabled = this.data.get("disableOrdering") === "false"
    const searchingEnabled = this.data.get("disableSearching") === "false"
    const searchPlaceholder = this.data.get("searchPlaceholder")
    const entriesDropDownEnabled = this.data.get("disableEntriesDropDown") === "false"
    const entriesToDisplay = parseInt(this.data.get("entriesToDisplay"))
    const lengthMenu = JSON.parse(this.data.get("lengthMenu"))
    const expandChildRowTarget = this.data.get("expandChildRowTarget")
    const domOverride = this.data.get("dom")
    const columnDefOverrides = JSON.parse(this.data.get("columnDefs"))
    const realtimeSettings = JSON.parse(this.data.get("realtimeSettings"))
    const bPaginate = this.data.get("disablePagination") === "false"
    const bInfo = this.data.get("disableBinfo") === "false"

    const classTargets = []
    const orderableTargets = []
    const searchableTargets = []
    const defaultOrderByPriority = {}
    const sortSequence = {}

    this.element[this.identifier] = this
    this.queryData = queryData

    headers.forEach((header, i) => {
      if(header.default_sort_priority !== undefined) {
        defaultOrderByPriority[header.default_sort_priority] = [i, header.sort_sequence[0]]
      }
      if(header.classes) {
        classTargets.push({
          targets: i,
          className: header.classes
        })
      }
      if(!header.orderable) {
        orderableTargets.push(i)
      }
      if(!header.searchable) {
        searchableTargets.push(i)
      }
      if(header.sort_sequence) {
        if(sortSequence[header.sort_sequence]) {
          sortSequence[header.sort_sequence].push(i)
        }
        else {
          sortSequence[header.sort_sequence] = [i]
        }
      }
    })

    const defaultOrder = Object.keys(defaultOrderByPriority).sort().map(
      key => defaultOrderByPriority[key]
    )

    const sortSequenceColumnDef = Object.keys(sortSequence).map(
      seq => ({ targets: sortSequence[seq], orderSequence: seq.split(",") })
    )

    const regularDom =
      "<'row'<'search pull-left relative bottom-11 w-1/4'f <'i icon-before icon-solid icon-search relative bottom-8 pl-2 w-2 text-gray-400'>>" +
      "<'row '<''tr>>" +
        "<'row tw border-t-2 border-huntress-grey-50'>" +
          "<'row tw pagination-controls flex-row flex justify-between items-center mt-4'" +
            "<'flex-row flex justify-between items-center'"+
              "<'entries text-sm pr-3'i>" +
              "<'per-page per-page-selector text-sm border-l px-3'l>" +
            ">" +
            "<'datatable-pagination h-12 px-1 py-2 border-gray-300 justify-start items-center inline-flex'p>" +
          ">"
    const dom = domOverride || regularDom

    const controllerContext = this

    var columnDefs = [
      ...columnDefOverrides,
      ...sortSequenceColumnDef,

      { targets: orderableTargets, orderable: false },
      { targets: searchableTargets, searchable: false },
      ...classTargets,
      { targets: "_all", class: "py-4 pl-4 pr-3 text-sm font-medium sm:pl-0" },    // add class to td

    ]

    const options = {
      aaSorting: [],
      autoWidth: false,
      destroy: true,
      processing: true,
      iDisplayLength: entriesToDisplay,
      lengthChange: entriesDropDownEnabled,
      lengthMenu: lengthMenu,
      stateSave: true,
      stateDuration: 300,
      serverSide: true,
      responsive: true,
      ordering: orderingEnabled,
      searching: searchingEnabled,
      language: {
        searchPlaceholder: searchPlaceholder,
        search: ""
      },
      oLanguage: {
        oPaginate: {
          sPrevious: "← Prev",
          sNext: "Next →"
        },
        sProcessing: "<div class=\"text-center\"><svg aria-hidden=\"true\" class=\"inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600\" viewBox=\"0 0 100 101\" fill=\"none\"><path d=\"M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z\" fill=\"currentColor\"/><path d=\"M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z\" fill=\"currentFill\"/></svg></div>"
      },
      order: defaultOrder,
      dom: dom,
      columnDefs: columnDefs,
      realtime: realtimeSettings,
      bPaginate: bPaginate,
      bInfo: bInfo,
      ajax: {
        url: dataUrl,
        data: function(data) {
          return $.extend( {}, data, controllerContext.queryData )
        }
      },
      drawCallback: function(settings) {
        controllerContext.attachResponsiveClasses()
        attachModalLinkEvents.bind(controllerContext.tableTarget)()

        let event = new CustomEvent("components.table.drawCallback",  { bubbles: true, detail: { dataTable: this, settings: settings } })
        controllerContext.tableTarget.dispatchEvent(event)

        const pageInfo = this.api().page.info()
        if(pageInfo.page > 0 && pageInfo.page >= pageInfo.pages) {
          setTimeout(() => {
            this.api().page(0).draw()
          }, 100)
        }
        // initilize flowbite so it can find elements after DataTable renders it
        initFlowbite()
      },
    }
    // pagination styling
    // parent div
    $.fn.dataTable.ext.classes.sPaging = "s-paging tw artemis-table-paginate h-12 px-1 py-2 border-gray-300 justify-start items-center inline-flex"
    // li elements for page buttons
    $.fn.dataTable.ext.classes.sPageButton = "tw p-2 rounded-lg gap-1 text-sm font-normal leading-none cursor-pointer text-blue-700 inline p-1 [&.active]:bg-blue-300 hover:bg-blue-100"

    // per_page select classes
    $.fn.dataTable.ext.classes.sLengthSelect = "sLengthSelect p-2 text-right text-sm pr-8 border-0 rounded-lg font-normal text-blue-500 focus:text-inherit cursor-pointer leading-none bg-inherit hover:bg-blue-100 hover:text-blue-500"

    // search filter classes
    $.fn.dataTable.ext.classes.sFilterInput = "sFilterInput tw indent-5 w-40 md:w-80 rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-gray-300 text-base sm:leading-6 py-1.5, font-normal font-['FontAwesome'] placeholder:text-gray-400"
    $.fn.dataTable.ext.classes.sSearch = ""

    // tr classes
    $.fn.dataTable.ext.classes.sStripeOdd = "hover:bg-gray-100 "
    $.fn.dataTable.ext.classes.sStripeEven = "hover:bg-gray-100 "

    // header sort classes
    $.fn.dataTable.ext.classes.sSortAsc =  "icon-after icon-solid icon-sort-up icon-space-xs"
    $.fn.dataTable.ext.classes.sSortDesc =  "icon-after icon-solid icon-sort-down icon-space-xs"
    $.fn.dataTable.ext.classes.sSortable =  "icon-after icon-solid icon-sort icon-space-xs"
    // $.fn.dataTable.models.oColumn.sClass = ""

    // loader container classes
    // $.fn.dataTable.ext.classes.sProcessing = "dataTables_processing panel  bg-white w-40 h-20 shadow"

    // these classes are not applied, we should not use them
    // $.fn.dataTable.ext.classes.sPageButtonDisabled = "sPageButtonDisabled "
    // $.fn.dataTable.ext.classes.sPageButtonActive = "sPageButtonActive bg-[#9bc3fd]"

    if (expandChildRowTarget) {
      initTableWithChildren($(this.tableTarget), options, expandChildRowTarget)
    }
    else {
      $(this.tableTarget).dataTable(options)
    }
  }
}