import FeatureUpdateController from "../../components/feature_update_controller"

export default class extends FeatureUpdateController {
  static targets = [
    "organizationSelect"
  ]

  initializeSelect2() {
    $(this.organizationSelectTarget).select2({
      placeholder: "Select an organization",
      theme: "bootstrap",
      allowClear: true,
      width: "100%",
    })

    this.initializeEntitySelect2()  
  }

  removeSelect2() {
    $(this.organizationSelectTarget).select2("destroy")
    $(this.entitySelectTarget).select2("destroy")
    $(this.entitySelectTarget).val(null)
    $(this.organizationSelectTarget).val(null)
  }

  onOrganizationSelect() {
    this.clearEntitySelection()
  }

  registerListeners() {
    $(this.entitySelectTarget).on("select2:select select2:clear", this.onEntitySelect.bind(this))
    $(this.organizationSelectTarget).on("select2:select select2:clear", this.onOrganizationSelect.bind(this))
  }

  removeListeners() {
    $(this.entitySelectTarget).off("select2:select select2:clear")
    $(this.organizationSelectTarget).off("select2:select select2:clear")
  }

  get organizationId() {
    let selectedData = $(this.organizationSelectTarget).select2("data")
    return selectedData.length > 0 ? selectedData[0].id : null
  }
}
