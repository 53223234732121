import { datadogRum } from '@datadog/browser-rum';

datadogRum.init({
    applicationId: '6c5fd863-d2d8-4e13-84ab-932626ea3ef7',
    clientToken: 'pube12e9c5583184642882eb0a396166614',
    site: 'datadoghq.com',
    service: 'huntress.io',
    env: 'production',
    version: document.querySelector("meta[name=application_version]")?.content,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    defaultPrivacyLevel: 'mask',
    enableExperimentalFeatures: ["feature_flags"]
});

datadogRum.setUser({
  id: document.querySelector("meta[name=user_id]")?.content,
  name: document.querySelector("meta[name=user_name]")?.content,
  email: document.querySelector("meta[name=user_email]")?.content,
  account_id: document.querySelector("meta[name=account_id]")?.content,
})

// Define a function that can be called at the bottom of the page to add all
// our Feature Flag evaluations
window.addFeatureFlagEvaluations = (features) => {
  for (let [key, val] of features) {
    datadogRum.addFeatureFlagEvaluation(key, val)
  }
}
