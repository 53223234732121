import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    copy: String,
  }

  createNode(text) {
    const node = document.createElement("pre")
    node.style.width = "1px"
    node.style.height = "1px"
    node.style.position = "fixed"
    node.style.top = "5px"
    node.textContent = text
    return node
  }

  showCopy(button) {
    const icon = button.querySelector("i")
    icon.classList.add("icon-copy")
    icon.classList.remove("icon-check")
  }

  showCheck(button) {
    const icon = button.querySelector("i")
    icon.classList.add("icon-check")
    icon.classList.remove("icon-copy")
  }

  async copy(e) {
    const button = e.target.closest("button")
    const id = button.getAttribute("for")
    const text = this.copyValue

    if (button.getAttribute("disabled") === "true") {
      return
    }

    if (text) {
      await this.copyText(text)
    } else if (id) {
      const root = "getRootNode" in Element.prototype ? button.getRootNode() : button.ownerDocument
      if (!(root instanceof Document || ("ShadowRoot" in window && root instanceof ShadowRoot))) {
        return
      }

      const node = root.getElementById(id)
      if (node) {
        await this.copyTarget(node)
      }
    }

    this.showCheck(button)

    setTimeout(() => {
      this.showCopy(button)
    }, 2000)
  }

  copyTarget(content) {
    if (content instanceof HTMLInputElement || content instanceof HTMLTextAreaElement) {
      this.copyText(content.value)
    } else if (content instanceof HTMLAnchorElement && content.hasAttribute("href")) {
      this.copyText(content.href)
    } else {
      this.copyNode(content)
    }
  }

  copyNode(node) {
    if ("clipboard" in navigator) {
      return navigator.clipboard.writeText(node.textContent || "")
    }

    const selection = getSelection()
    if (selection == null) {
      return Promise.reject(new Error())
    }

    selection.removeAllRanges()

    const range = document.createRange()
    range.selectNodeContents(node)
    selection.addRange(range)

    document.execCommand("copy")
    selection.removeAllRanges()
    return Promise.resolve()
  }

  copyText(text) {
    if ("clipboard" in navigator) {
      return navigator.clipboard.writeText(text)
    } else {
      const body = document.body
      if (!body) {
        return Promise.reject(new Error())
      }

      const node = this.createNode(text)
      body.appendChild(node)
      this.copyNode(node)
      body.removeChild(node)
      return Promise.resolve()
    }
  }
}
