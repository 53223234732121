import BulkActionsController from "../../../components/bulk_actions_controller"
import { getSelectedIds } from "../../../../helpers/bulkActionHelpers"
import { initTableWithChildren } from "huntressHelpers"
import { flashMessage } from "huntressHelpers"

export default class extends BulkActionsController {
  static targets = ["loading", "datatable", "table", "categorizeModal", "container", "bulkUnapprove", "bulkReportLink"]

  connect() {
    this.setupSingleToggleCallbacks()
    this.setupSelect2AutoOpen()
    this.setupDataTable()
  }

  setupSelect2AutoOpen() {
    $(this.categorizeModalTarget).on("shown.bs.modal", () => {
      $(this.categorizeModalTarget.querySelector("select")).select2("open")
    })
  }

  setupDataTable() {
    $(this.tableTarget)
      .on("init.dt", this.tableInitialized.bind(this))
      .on("draw.dt", this.uncheckSelectAllBox.bind(this))
    const agent_id = $("#org-agents-autoruns-index").data("agentId")
    const lastAutorunsTab = localStorage.getItem("lastAutorunsTab_" + agent_id)
    if (lastAutorunsTab) {
      $("a[data-classification=\"" + lastAutorunsTab + "\"]").tab("show")
      if(this.hasBulkUnapproveTarget && $("a[data-classification=\"" + lastAutorunsTab + "\"]").data("showbulkunapprove")) {
        this.bulkUnapproveTarget.classList.remove("hide")
      }
    }
    initTableWithChildren( $(this.tableTarget), this.getDatatableParams($("#datatable ul li.active a").data("classification"), $("#datatable ul li.active a").data("showdetector"),$("#datatable ul li.active a").data("showrules"), $("#datatable ul li.active a").data("showreported")), ".details")
  }


  tableInitialized() {
    $(this.loadingTarget).hide()
    $(this.datatableTarget).fadeIn()
  }

  uncheckSelectAllBox() {
    $("#bulk-select-all").prop("checked", false)
  }

  setupSingleToggleCallbacks() {
    const element = this.hasContainerTarget ? this.containerTarget : this.element
    $(element).on("click", ".bulk-select", this.updateBulkReportLink.bind(this))

    super.setupSingleToggleCallbacks()
  }

  getDatatableParams(ajax_url, showDetector, showRules = false, showReported) {
    // detector and rule are always returned from the server and hidden here in columnDefs
    // checkbox, reported status and signals columns are not returned from the server when hidden
    const bulkActionsEnabled = $(".bulk-buttons").length !== 0
    const detectorIndex = bulkActionsEnabled ? 1 : 0
    const dateAddedIndex = detectorIndex + 4
    const reportedIndex = detectorIndex + 7
    const ruleIndex = detectorIndex + (showReported ? 9 : 7)

    let columnDefs = [
      { targets: detectorIndex, orderable: false, visible: showDetector },
      { targets: ruleIndex, orderable: false, visible: showRules },
      { targets: -1, orderable: false }, // last column = details
    ]

    if (bulkActionsEnabled) {
      columnDefs.push({ targets: 0, orderable: false })
    }

    if (showReported) {
      columnDefs.push({ targets: reportedIndex + 1, orderable: false })
    }

    return {
      columnDefs: columnDefs,
      order: [dateAddedIndex, "desc"],
      searching: true,
      language: {
        emptyTable: "No autoruns found for this classification",
      },
      deferRender: true,
      responsive: true,
      autoWidth: false,
      destroy: true,
      processing: true,
      iDisplayLength: 25,
      stateSave: true,
      stateDuration: 300,
      serverSide: true,
      ajax: ajax_url,
      createdRow: function(row) {
        $(row).addClass("cursor-pointer")
      }
    }
  }

  tabChanged(evt) {
    if (!this.hasTableTarget) {
      return
    }
    let table = $(this.tableTarget).DataTable()
    const agentId = $("#org-agents-autoruns-index").data("agentId")
    table.state.clear()
    table.destroy()
    table = initTableWithChildren($(this.tableTarget), this.getDatatableParams($(evt.currentTarget).data("classification"), $(evt.currentTarget).data("showdetector"), $(evt.currentTarget).data("showrules"), $(evt.currentTarget).data("showreported")), ".details")
    localStorage.setItem("lastAutorunsTab_" + agentId, $(evt.currentTarget).data("classification"))

    if($(evt.currentTarget).data("showbulkunapprove") && this.hasBulkUnapproveTarget) {
      this.bulkUnapproveTarget.classList.remove("hide")
    } else if(this.hasBulkUnapproveTarget) {
      this.bulkUnapproveTarget.classList.add("hide")
    }
  }

  handleCategorizeAjaxError(evt) {
    const errorMsg = evt.detail[0].error
    $(this.datatableTarget).prepend("<div class='alert alert-danger alert-dismissible animated fadeIn'><button class='close' data-dismiss='alert'>×</button>" + errorMsg + "</div>")
    $(this.categorizeModalTarget).modal("hide")
  }

  refreshWindow() {
    window.location.reload(true)
  }

  bulkUnapprove() {
    let ids = this.getSelectedIds()
    let parent = document.getElementById("org-agents-autoruns-index")

    $.ajax({
      url: this.bulkUnapproveTarget.dataset["url"],
      dataType: "json",
      contentType: "application/json",
      method: "POST",
      data: JSON.stringify({ ids: ids }),
      success:  (data) => {
        if(data["error"]) {
          flashMessage(data["message"], "warning", parent)
        } else {
          flashMessage(data["message"], "success", parent)
          $(this.tableTarget).DataTable().draw()
          this.disableBulkActions()
        }
      },
      error: (xhr, ajaxOptions, thrownError) => {
        flashMessage("An error occurred while unapproving the selected autoruns", "warning", parent)
      }
    })
  }

  updateBulkReportLink() {
    if (!this.hasBulkReportLinkTarget) {
      return
    }

    const target = this.bulkReportLinkTarget
    const url = new URL(target.dataset.url, window.location.origin)
    const ids = getSelectedIds(target.dataset.scope).split(", ")
    ids.forEach(id => url.searchParams.append("ids[]", id))

    target.href = url.toString()
  }
}
