import { Controller } from "@hotwired/stimulus"

// Delete file when cleaning up :tasks_datagrid feature
export default class extends Controller {

  connect(){
    $(this.element).on("click", "td", (e) => {
      let target = $(e.target)
      let tr = $(target.parents("tr")[0])
      let btn = $(tr).find(".row-expand-btn")

      if(!btn)
        return

      if(target.hasClass("row-expand-btn")){
        tr.trigger("click")
        this.rotateBtn(btn)
      }
      else if(target.is("tr,td")){
        this.rotateBtn(btn)
      }
    })
  }

  rotateBtn(btn){
    // CSS animations
    if(btn.hasClass("rotate")){
      btn.removeClass("rotate")
    }
    else{
      btn.addClass("rotate")
    }
  }
}
