import ApexCharts from "apexcharts"
import { initFlowbite } from "flowbite"
import ChartController from "./chart_controller"

export default class extends ChartController {
  static targets = ["chart", "dropdown"]
  static chartObj

  connect() {
    const chart = this.chartTarget
    const data = JSON.parse(this.data.get("data"))
    const labels = JSON.parse(this.data.get("labels"))
    const colors = JSON.parse(this.data.get("colors"))
    const highlight = JSON.parse(this.data.get("highlight"))

    const options = {
      series: data,
      colors: colors,
      chart: {
        height: "300px",
        type: "donut",
      },
      stroke: {
        colors: ["transparent"],
        lineCap: "",
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              name: {
                show: true,
                offsetY: 20,
              },
              total: {
                showAlways: true,
                show: true,
                color: "var(--tw-color-gray-500)",
                fontFamily: "Work Sans, Nunito Sans, ui-sans-serif, system-ui, sans-serif",
                label: " ",
                formatter: function (w) {
                  return "Issues"
                },
              },
              value: {
                show: true,
                offsetY: -20,
                color: "var(--tw-color-gray-500)",
                fontSize: "1.5rem",
                fontFamily: "Work Sans, Nunito Sans, ui-sans-serif, system-ui, sans-serif",
                formatter: function (value) {
                  return new Intl.NumberFormat("en-US").format(value)
                },
              },
            },
            size: "70%",
          },
        },
      },
      grid: {
        padding: {
          top: -2,
        },
      },
      labels: labels,
      dataLabels: {
        enabled: false,
      },
      legend: {
        fontFamily: "Nunito Sans, sans-serif",
        markers: {
          shape: "square"
        }
      },
      tooltip: {
        theme: "light",
      },
      yaxis: {
        labels: {
          formatter: function(value) {
            const formatted_value = new Intl.NumberFormat("en-US").format(value)
            const total = parseInt(highlight)
            const percentage = (value / total).toFixed(3) * 100
            return `${percentage}% (${formatted_value} Agents)`
          },
        },
      },
      xaxis: {
        labels: {
          formatter: function (value) {
            let formatted_value = new Intl.NumberFormat("en-US").format(value)
            return formatted_value
          },
        },
        axisTicks: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
      },
    }

    this.chartObj = new ApexCharts(chart, options)
    this.chartObj.render()

    initFlowbite()
  }
}
