import { Controller } from "@hotwired/stimulus"
import "leaflet/dist/leaflet.css"
import "leaflet.markercluster/dist/MarkerCluster.css"
import L from "leaflet"
import "leaflet.markercluster/dist/leaflet.markercluster.js"
import * as Routes from "routes"
import { Turbo } from "@hotwired/turbo-rails"

export default class extends Controller {
  static targets = ["welcomeModal", "welcomeModalDismiss"]

  connect() {
    this.setUpMap()
    this.firstTimeModal()
  }

  setUpMap(){
    let markerClicked = false
    const organizationId = $("#map").data("organization-id")

    let map = L.map("map").setView([30, 0], 2)
    L.tileLayer("https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.{ext}", {
      minZoom: 1,
      maxZoom: 20,
      attribution: '&copy; <a href="https://www.stadiamaps.com/" target="_blank">Stadia Maps</a> &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      ext: "png"
    }).addTo(map)
    map.setMaxBounds(map.getBounds())

    let markers = L.markerClusterGroup({
      singleMarkerMode: true,
      iconCreateFunction: function(cluster) {
        let markers = cluster.getAllChildMarkers()
        let n = 0
        for (var i = 0; i < markers.length; i++) {
          n += markers[i].number
        }
        return L.divIcon({ className: "marker-cluster", html: "<div><span>" + humanNumber(n) + "</span></div>", iconSize: L.point(40, 40) })
      }
    })

    const mapLocations = $("#map").data("map-locations")
    for(let i = 0; i < mapLocations.length; i++){
      const location = mapLocations[i]
      const marker = L.marker(L.latLng(location[0], location[1]))
      const url = organizationId !== undefined ? Routes.organization_country_login_event_path(location[3], {organization_id: organizationId}) : Routes.account_country_login_event_path({id: location[3]})

      marker.number = location[2]
      markers.addLayer(marker)

      marker.on("click", async () => {
        if(!markerClicked){
          const response = await fetch(url, {
            method: "GET",
            headers: {
              "Turbo-Frame": "hotwire-drawer"
            }
          })

          if (response.ok) {
            const html = await response.text()
            Turbo.renderStreamMessage(html)
          }

          markerClicked = false
        }})
    }

    map.addLayer(markers)
  }

  firstTimeModal(){
    const dismissed = localStorage.getItem("unwantedAccessModalDismissed")

    if (dismissed)
      return

    $(this.welcomeModalDismissTarget).on("click", ()=>{
      localStorage.setItem("unwantedAccessModalDismissed", "true")
    })

    $(this.welcomeModalTarget).modal("show")
  }
}

/**
 * Abbreviates a number to a shorter text format using "K" for thousands, "M" for millions, and "B" for billions.
 *
 * @param {number} num - The number to abbreviate.
 * @returns {string} - The abbreviated number as a string with one decimal point if needed,
 *                     followed by "K", "M", or "B" for thousands, millions, or billions, respectively.
 *
 * @example
 * humanNumber(1500);        // "1.5K"
 * humanNumber(250669999);   // "250.7M"
 * humanNumber(1364000000);  // "1.4B"
 * humanNumber(999);         // "999" (no abbreviation for numbers below 1000)
 */
function humanNumber(num) {
  if (num >= 1_000_000_000) {
    return (num / 1_000_000_000).toFixed(1).replace(/\.0$/, "") + "B"
  } else if (num >= 1_000_000) {
    return (num / 1_000_000).toFixed(1).replace(/\.0$/, "") + "M"
  } else if (num >= 1_000) {
    return (num / 1_000).toFixed(1).replace(/\.0$/, "") + "K"
  } else {
    return num.toString()
  }
}
