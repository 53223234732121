import { Controller } from "@hotwired/stimulus"
import throttle from "lodash.throttle"

export default class extends Controller {
  static targets = [
    "scrollPane"
  ]

  initialize() {
    this.throttledScroll = throttle(() => { this.#processScroll() }, 50)
  }

  connect() {
    // We have to use RAF here to wait for the table to be properly rendered
    // before we check if it's scrollable.
    requestAnimationFrame(() => {
      this.#processScroll()
    })
  }

  scroll(e) {
    this.throttledScroll()
  }

  resize(e) {
    this.scroll()
  }

  #processScroll() {
    const scrollPane = this.scrollPaneTarget
    const scrollable = this.#scrollable(scrollPane)

    this.element.classList.toggle("scrollable", scrollable)
    this.element.classList.toggle("scroll-start", scrollable && this.#isScrolledToLeft(scrollPane))
    this.element.classList.toggle("scroll-end", scrollable && this.#isScrolledToRight(scrollPane))

    const firstColumnHeader = this.element.querySelector("table th.sticky-left")
    if (firstColumnHeader) {
      this.element.style.setProperty("--sticky-left-width", `${firstColumnHeader.clientWidth}px`)
    }

    const lastColumnHeader = this.element.querySelector("table th.sticky-right")
    if (lastColumnHeader) {
      this.element.style.setProperty("--sticky-right-width", `${lastColumnHeader.clientWidth}px`)
    }
  }

  #isScrolledToLeft(element) {
    return element.scrollLeft === 0
  }

  #isScrolledToRight(element) {
    return (element.scrollWidth - element.clientWidth - element.scrollLeft) < 1
  }

  #scrollable(element) {
    return element.scrollWidth > element.clientWidth
  }
}
