import { Controller } from "@hotwired/stimulus"
import debounce from "lodash.debounce"

export default class extends Controller {
  static targets = ["searchInput"]

  initialize() {
    this.debounceSubmit = debounce(() => {
      this.searchInputTarget.form.requestSubmit()
    }, 300)
  }

  submitSearch() {
    this.debounceSubmit()
  }

  updateSearch(ev) {
    const search = ev.currentTarget

    if (search.dataset.value) {
      const searchEv = new Event("change", { bubbles: true })

      this.searchInputTarget.value = search.dataset.value
      this.searchInputTarget.dispatchEvent(searchEv)
    }

    this.submitSearch()
  }
}
