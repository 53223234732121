import { Controller } from "@hotwired/stimulus"
import { Tooltip } from "flowbite"

export default class extends Controller {
  static targets = [
    "tooltip",
    "tooltipTrigger"
  ]

  static values = {
    showOn: { type: String, default: "hover" },
    placement: { type: String, default: "bottom" }
  }

  connect() {
    this.tooltip = new Tooltip(
      this.tooltipTarget,
      this.tooltipTriggerTarget, {
        placement: this.placementValue, triggerType: this.showOnValue
      }
    )
  }

  disconnect() {
    this.tooltip.destroy()
  }
}
