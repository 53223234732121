import { Controller } from "@hotwired/stimulus"
import { initTableWithChildren } from "huntressHelpers"

export default class extends Controller {
  static targets = ["form", "loading", "datatable", "table"]

  connect() {
    this.setupDataTable()
  }

  submitForm() {
    this.formTarget.submit()
  }

  setupDataTable() {
    this.setupAjaxEventHandlers()

    let options = {
      autoWidth: false,
      destroy: true,
      processing: true,
      iDisplayLength: 200,
      lengthMenu: [10, 25, 50, 100, 200, 500],
      stateSave: true,
      stateDuration: 300,
      serverSide: true,
      columnDefs: [
        { targets: "_all", searchable: false },
        { targets: [0, 1, 6, -1], orderable: false },
      ],
      order: [[5, "desc"]],
      searching: true,
      pagingType: "simple",
      deferRender: true,
      responsive: true,
      language: {
        "info": "Showing _START_ to _END_ of many",
        "infoFiltered": ""
      },
      ajax: this.data.get("ajaxUrl"),
      createdRow: function(row) {
        $(row).addClass("cursor-pointer")
      }
    }
    initTableWithChildren($(this.tableTarget), options, ".bulk-select")
  }

  setupAjaxEventHandlers() {
    $(this.tableTarget).on("init.dt", () => this.tableInitialized())
      .on("draw.dt", () => {
        $("a.reviewed").on("ajax:success", this.reviewedSuccess.bind(this))
        $("a.investigate")
          .on("ajax:success", this.investigateSuccess.bind(this))
          .on("ajax:error", this.investigateError.bind(this))
      })
  }

  tableInitialized() {
    $(this.loadingTarget).hide()
    $(this.datatableTarget).fadeIn()
  }

  // The functions below cannot use data targets because the elements are dynamically generated by jQuery DataTables
  reviewedSuccess(evt) {
    const el = $(evt.currentTarget)

    // Disable the review button
    el.attr("disabled", "disabled").addClass("disabled")

    // Fade the row except the last column with the buttons
    el.closest("tr").find("td:not(:last)").fadeTo(500, 0.25)
  }

  investigateSuccess(evt) {
    const el = $(evt.currentTarget)
    el.attr("disabled", "disabled").addClass("disabled")
    el.siblings().attr("disabled", "disabled").addClass("disabled")

    // Fade the row except the last column with the buttons
    el.closest("tr").find("td:not(:last)").fadeTo(500, 0.25)

    $("#alert-box").append("<div class='alert alert-success alert-dismissible animated fadeIn'><button class='close' data-dismiss='alert'>×</button>Investigation created.</div>")
  }

  investigateError(evt) {
    const errorMsg = evt.detail[0].error
    const xhr = evt.detail[2]
    alert(`Error: ${xhr.status} - ${errorMsg}`)
  }
}
