import FilterMenuController from "./filter_menu_controller"
import { DATEPICKER_SETTINGS } from "./datepicker_controller"

export default class ExtendedFilterMenuController extends FilterMenuController {
  static targets = [
    "filters",
    "from",
    "to",
    "accounts",
    "organizations",
    "entities"
  ]

  static outlets = ["components--entity-drill-down"]

  connect() {
    this.element[this.identifier] = this

    this.loadPageReloadSettings()

    $(this.filtersTarget).find(".filter-menu-contents").on("click", function (e) {
      e.stopPropagation()
    })

    if (this.hasFromTarget) {
      $(this.fromTarget).datepicker(this.fromDatePickerSettings).on("changeDate", this.applyFilters.bind(this))
    }

    if (this.hasToTarget) {
      $(this.toTarget).datepicker(this.toDatePickerSettings).on("changeDate", this.applyFilters.bind(this))
    }


    if (this.hasAccountsTarget) {
      $(this.accountsTarget).on("select2:close", this.applyFilters.bind(this))
    }

    if (this.hasOrganizationsTarget) {
      $(this.organizationsTarget).on("select2:close", this.applyFilters.bind(this))
    }

    if (this.hasEntitiesTarget) {
      $(this.entitiesTarget).on("select2:close", this.applyFilters.bind(this))
    }

    $(this.filtersTarget).find("select").on("change.select2", this.applyFilters.bind(this))
  }

  get datePickerSettings() {
    return { ...DATEPICKER_SETTINGS, orientation: "bottom" }
  }

  get fromDatePickerSettings() {
    const startDate = this.fromTarget.dataset.startDate
    const earliestDate = new Date(Date.parse(startDate))
    const tooltip = "Data retention for Signals Investigated is 180 days. If more information is required contact Huntress Support."
    return startDate ? {
      ...this.datePickerSettings,
      startDate: startDate,
      beforeShowDay(date) {
        if (date >= earliestDate) {
          return {}
        } else {
          return {
            tooltip: tooltip
          }
        }
      },
      beforeShowMonth(date) {
        if (date.getFullYear() * 13 + date.getMonth() >= earliestDate.getFullYear() * 13 + earliestDate.getMonth()) {
          return {}
        } else {
          return {
            tooltip: tooltip
          }
        }
      },
      beforeShowYear(date) {
        if (date.getFullYear() >= earliestDate.getFullYear()) {
          return {}
        } else {
          return {
            tooltip: tooltip
          }
        }
      }
    } : this.datePickerSettings
  }

  get toDatePickerSettings() {
    const startDate = this.toTarget.dataset.startDate
    return startDate ? { ...this.datePickerSettings, startDate: startDate } : this.datePickerSettings
  }

  clearDate() {
    $(this.fromTarget).datepicker("setDate", null)
    $(this.toTarget).datepicker("setDate", null)
  }

  setDateRange(e) {
    let dates = JSON.parse(e.target.dataset.dates)

    $(this.toTarget).datepicker("setDate", dates["to_date"] || null)
    $(this.fromTarget).datepicker("setDate", dates["from_date"] || null)
  }

  setScopes(account, organization, entity) {
    this.componentsEntityDrillDownOutlet.setScopes(account, organization, entity)
    this.applyFilters()
  }

  clearFilters() {
    this.clearCheckedFilters()
    this.clearDate()
  }

  get search() {
    let search = this.searchFromFilters

    if (this.hasFromTarget) {
      if (this.fromTarget.value.length === 10) {
        search.set("filters[from_date]", this.fromTarget.value)
      }
    }

    if (this.hasToTarget) {
      if (this.toTarget.value.length === 10) {
        search.set("filters[to_date]", this.toTarget.value)
      }
    }

    if (this.hasAccountsTarget) {
      $(this.accountsTarget).val().forEach((accountId) => { search.append("filters[account_ids][]", accountId) })
    }

    if (this.hasOrganizationsTarget) {
      $(this.organizationsTarget).val().forEach((organizationId) => {search.append("filters[organization_ids][]", organizationId)})
    }

    if (this.hasEntitiesTarget) {
      $(this.entitiesTarget).val().forEach((entityId) => {search.append("filters[entity_ids][]", entityId)})
    }

    for (let select of $(this.filtersTarget).find("select")) {
      if (["accounts[]", "organizations[]", "entities[]"].includes(select.name)) {
        continue
      }

      if (select.multiple) {
        let filterName = `filters[${select.name.replace("[]", "")}][]`
        $(select).val().forEach((val) => {
          search.append(filterName, val)
        })
      } else {
        let filterName = `filters[${select.name}]`
        search.append(filterName, $(select).val())
      }
    }

    return search
  }
}
