import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.#toggleDarkMode(this.element.dataset.darkMode === "true")
  }

  #toggleDarkMode(darkMode) {
    if (darkMode) {
      document.body.classList.add("dark")
    } else {
      document.body.classList.remove("dark")
    }
  }
}
