import { Controller } from "@hotwired/stimulus"
import * as Routes from "routes"

export default class extends Controller {
  static targets = [
    "agentIdInputs",
    "agentIds",
    "agentsDatatable",
    "agentsEditButton",
    "agentsFooter",
    "agentsList",
    "agentsNextButton",
    "agentsPanel",
    "agentsSelectAll",
    "archiveEndDate",
    "archiveStartDate",
    "form",
    "productType",
    "productTypeInput",
    "productFooter",
    "productEditButton",
    "productNextButton",
    "productPanel",
    "productSelections",
    "organizationsDatatable",
    "organizationsEditButton",
    "organizationsFooter",
    "organizationIdInputs",
    "organizationsList",
    "organizationsNextButton",
    "organizationsPanel",
    "organizationSelectAll",
    "timeframePanel",
    "timeframeText",
    "timeframeTitle",
    "saveButton"
  ]

  connect() {
    this.selectedIds = {"organization": [],"agent": []}
    this.selectAll = {"organization": false, "agent": false}
    this.productType = null

    this.panels = [this.timeframePanelTarget,this.agentsPanelTarget,this.organizationsPanelTarget, this.productPanelTarget]
    this.editButtons = [this.agentsEditButtonTarget, this.productEditButtonTarget, this.organizationsEditButtonTarget]
    this.nextButtons = [this.agentsNextButtonTarget, this.productNextButtonTarget, this.organizationsNextButtonTarget]

    // Setup the organizations table
    $(this.organizationsDatatableTarget).dataTable({
      autoWidth: false,
      destroy: true,
      processing: true,
      lengthMenu: [10, 25, 50, 100, 200, 500],
      lengthChange: true,
      stateSave: true,
      stateDuration: 300,
      serverSide: true,
      columnDefs: [
        { targets: 0, searchable: false, orderable: false }
      ],
      order: [[1, "asc"]],
      searching: true,
      deferRender: true,
      responsive: true,
      ajax: Routes.organizations_account_data_archives_path(),
      createdRow: this.preSelectedRow.bind(this, "organization"),
    })
  }

  createSelectionElement(text, action, attrs) {
    const el = document.createElement("span")
    el.classList = "label label-success"
    el.innerText = text

    for (const key in attrs) {
      el.setAttribute("data-" + key, attrs[key])
    }

    const close = document.createElement("a")
    close.classList = "pl-1"
    close.href = "#"
    close.innerHTML = "&times;"
    close.setAttribute("data-action", `click->${this.identifier}#${action}`)

    el.append(close)
    return el
  }

  preSelectedRow(data_type, row) {
    const class_object = data_type == "organization" ? "input.add-organization" : "input.add-agent"
    // When rendering rows we check if the row has already been selected so we can indicate with check box
    const checkBox = row.querySelector(`td ${class_object}`)
    if (this.selectedIds[data_type].some(id => checkBox.dataset.recordId === id)) {
      checkBox.checked = true
    } else if(this.selectAll[data_type]) {
      checkBox.checked = true
      checkBox.disabled = true
    }
  }

  recordCheckbox(event) {
    const recordType = event.currentTarget.dataset.recordType
    const checked = event.currentTarget.checked
    const recordId = event.currentTarget.dataset.recordId
    const listTarget = this.recordListTarget(recordType)

    if(checked) {
      if (this.selectedIds[recordType].some(id => recordId === id)) {
        return
      }

      listTarget.append(
        this.createSelectionElement(
          event.currentTarget.dataset.recordName,
          "removeRecordTag",
          {
            "record-id": recordId,
            "record-type": recordType
          }
        )
      )
      this.selectedIds[recordType].push(recordId)
    } else {
      this.selectedIds[recordType] = this.selectedIds[recordType].filter(item => item !== recordId)

      listTarget.children.forEach(element => {
        if (element.dataset.recordId == recordId){
          element.remove()
        }
      })
    }
  }

  toggleAllRecordsCheckbox(event) {
    const checked = event.target.checked
    const recordType = event.currentTarget.dataset.recordType

    this.toggleAllRecords(checked, recordType)
  }

  toggleAllRecords(checked, recordType) {
    const listTarget = this.recordListTarget(recordType)
    const panelTarget = this.recordPanelTarget(recordType)

    panelTarget.querySelectorAll("table tbody tr").forEach(row => {
      const checkBox = row.querySelector(`td input.add-${recordType}`)
      checkBox.checked = checked
      checkBox.disabled = checked
      this.selectAll[recordType] = checked
    })

    if (checked) {
      while (listTarget.firstChild) {
        listTarget.removeChild(listTarget.firstChild)
      }

      this.selectedIds[recordType] = ["all"]

      listTarget.append(
        this.createSelectionElement(
          `All ${recordType}s`,
          "removeAllRecordsTag",
          {
            "record-type": recordType
          }
        )
      )
    } else {
      this.selectedIds[recordType] = []

      listTarget.removeChild(listTarget.firstChild)
    }
  }

  removeAllRecordsTag(event) {
    const recordType = event.currentTarget.parentElement.dataset.recordType
    const selectAll = recordType == "organization" ? this.organizationSelectAllTarget : this.agentsSelectAllTarget
    selectAll.checked = false
    this.toggleAllRecords(false, recordType)
  }

  removeRecordTag(event) {
    const removedRecordId = event.currentTarget.parentElement.dataset.recordId
    const recordType = event.currentTarget.parentElement.dataset.recordType

    // Remove the record ID from the selected list
    this.selectedIds[recordType] = this.selectedIds[recordType].filter(item => item !== removedRecordId)
    const panelTarget = this.recordPanelTarget(recordType)

    // Uncheck box
    panelTarget.querySelectorAll("table tbody tr").forEach(row => {
      const checkBox = row.querySelector(`td input.add-${recordType}`)
      if (checkBox.dataset.recordId === removedRecordId) {
        checkBox.checked = false
      }
    })

    // Remove the label tag
    event.currentTarget.parentElement.remove()
  }

  editOrganizations() {
    // Show the organizations panel and disable the future steps
    this.showPanel(this.organizationsPanelTarget)
    this.disableSubmit()
    this.setTagFooter(this.organizationsListTarget, this.organizationsFooterTarget, true, true)
    this.resetButtons(this.organizationsNextButtonTarget, [])
    this.setTypePanels([this.timeframePanelTarget, this.agentsPanelTarget], true)
    // Reset organization selection on form
    this.organizationIdInputsTargets.forEach(el => el.remove())
    // Clear any selected agents
    this.clearAgents()
    this.clearProduct()
  }

  doneOrganizations() {
    if (this.selectedIds["organization"].length == 0) {
      alert("You must select at least one organization")
      return
    }

    this.setTagFooter(this.organizationsListTarget, this.organizationsFooterTarget, false, false)
    this.editProduct()

    // Add the IDs of the selected organizations to the form
    this.selectedIds["organization"].forEach(id => {
      const el = document.createElement("input")
      el.type = "hidden"
      el.name = "data_archive[organization_ids][]"
      el.dataset["dataArchives-NewTarget"] = "organizationIdInputs"
      el.value = id
      this.formTarget.append(el)
    })
  }

  selectProduct(event) {
    this.productType = event.currentTarget.value
    const displayText = event.currentTarget.dataset.displayText
    this.productTypeTarget.replaceChildren()

    this.productTypeTarget.append(this.createSelectionElement(displayText))

    this.productTypeTarget.querySelectorAll("span a").forEach(link => {
      link.classList.add("hidden")
    })
  }

  doneProduct() {
    if (!this.productType) {
      alert("You must select a product.")
      return
    }

    this.setTagFooter(this.productTypeTarget, this.productFooterTarget, false, false)
    this.setTimeframePanel()

    let nextNextButton = null
    if (this.productType == "edr"){
      this.setTypePanels([this.timeframePanelTarget,this.agentsPanelTarget], false)
      this.editAgents()
      nextNextButton = this.agentsNextButtonTarget
    } else if (this.productType == "siem") {
      this.setTypePanels([this.timeframePanelTarget], false)
      this.editTimeframe()
    }

    this.resetButtons(nextNextButton, [this.organizationsEditButtonTarget, this.productEditButtonTarget])

    const el = document.createElement("input")
    el.type = "hidden"
    el.name = "data_archive[product_type]"
    el.dataset["dataArchives-NewTarget"] = "productTypeInput"
    el.value = this.productType
    this.formTarget.append(el)
  }

  editProduct() {
    // Show the product panel and disable the future steps
    this.showPanel(this.productPanelTarget)
    this.resetButtons(this.productNextButtonTarget, [this.organizationsEditButtonTarget])
    this.setTagFooter(this.productTypeTarget, this.productFooterTarget, false, true)
    this.setTypePanels([this.timeframePanelTarget,this.agentsPanelTarget], true)

    this.productTypeInputTargets.forEach(el => el.remove())

    this.selectProductTarget
    this.disableSubmit()
    this.clearProduct()
    this.clearAgents()
  }

  clearProduct(){
    this.productTypeTarget.replaceChildren()
    this.productType = null

    this.productSelectionsTarget.children.forEach(item =>
      item.firstElementChild.checked = false
    )
  }

  clearAgents(){
    // Clear any selected agents
    this.agentsListTarget.replaceChildren()
    this.selectedIds["agent"] = []
    this.selectAll["agent"] = false
    this.agentsSelectAllTarget.checked = false
    this.agentIdInputsTargets.forEach(el => el.remove())
  }

  editAgents() {
    // Show the agents panel and restore buttons
    this.showPanel(this.agentsPanelTarget)
    this.disableSubmit()
    this.resetButtons(this.agenttNextButtonTarget, [this.organizationsEditButtonTarget, this.productEditButtonTarget])

    this.agentIdInputsTargets.forEach(el => el.remove())

    $(this.agentsDatatableTarget).dataTable({
      autoWidth: false,
      destroy: true,
      processing: true,
      iDisplayLength: 10,
      lengthMenu: [10, 25, 50, 100, 200, 500],
      serverSide: true,
      columnDefs: [
        { targets: [0], orderable: false, searchable: false },
      ],
      order: [[6, "desc"]],
      searching: true,
      deferRender: true,
      responsive: true,
      ajax: Routes.agents_account_data_archives_path(
        {
          format: "json",
          organization_ids: this.selectedIds["organization"]
        }
      ),
      createdRow: this.preSelectedRow.bind(this, "agent"),
    })

    this.setTagFooter(this.agentsListTarget, this.agentsFooterTarget, true, true)
  }

  doneAgents() {
    if (this.selectedIds["agent"].length == 0) {
      alert("You must select at least one agent")
      return
    }

    this.resetButtons(null, this.editButtons)
    this.setTagFooter(this.agentsListTarget, this.agentsFooterTarget, false, false)
    this.editTimeframe()

    // Add the IDs of the selected agents to the form
    this.selectedIds["agent"].forEach(id => {
      const el = document.createElement("input")
      el.type = "hidden"
      el.name = "data_archive[agent_ids][]"
      el.dataset["dataArchives-NewTarget"] = "agentIdInputs"
      el.value = id
      this.formTarget.append(el)
    })
  }

  setTimeframe(event) {
    let startDate = new Date()
    let endDate = new Date()

    switch (event.currentTarget.dataset.timeframeValue) {
    case "24h":
      startDate.setDate(startDate.getDate() - 1)
      break
    case "3d":
      startDate.setDate(startDate.getDate() - 3)
      break
    case "7d":
      startDate.setDate(startDate.getDate() - 7)
      break
    case "30d":
      startDate.setDate(startDate.getDate() - 30)
      break
    }

    // Update the form text boxes
    this.archiveStartDateTarget.value = this.formatDate(startDate)
    this.archiveEndDateTarget.value = this.formatDate(endDate)
  }

  formatDate(date) {
    return date.toISOString().slice(0, 10)
  }

  editTimeframe() {
    this.enableSubmit()
    this.showPanel(this.timeframePanelTarget)
  }

  setTimeframePanel() {
    const textContent = {
      "edr": {
        "title": "Step 4: Specify Timeframe",
        "text": "Process insights data will be available for up to 7 days from the current date."
      },
      "siem": {
        "title": "Step 3: Specify Timeframe",
        "text": "SIEM data is available for any dates, provided the data retention period includes those dates. Note that the maximum date range for a data archive request is 30 days."
      }
    }

    this.timeframeTitleTarget.textContent = textContent[this.productType]["title"]
    this.timeframeTextTarget.textContent = textContent[this.productType]["text"]
  }

  disableSubmit() {
    this.saveButtonTarget.classList.remove("btn-success")
    this.saveButtonTarget.classList.add("btn-default")
    this.saveButtonTarget.setAttribute("disabled", "disabled")
  }

  enableSubmit() {
    this.saveButtonTarget.classList.add("btn-success")
    this.saveButtonTarget.classList.remove("btn-default")
    this.saveButtonTarget.removeAttribute("disabled")
  }

  showPanel(target) {
    const panel = target.closest(".panel")

    this.panels.forEach(otherPanel => {
      const outerPanel = otherPanel.closest(".panel")
      if (outerPanel !== panel) {
        $(otherPanel).collapse("hide")
        outerPanel.classList.remove("panel-success")
        outerPanel.classList.add("panel-default")
      }
    })

    panel.classList.add("panel-success")
    panel.classList.remove("panel-default")
    $(target).collapse("show")
  }

  setTypePanels(panelList, hidden) {
    panelList.forEach(panelList => {
      const panel = panelList.closest(".panel")
      hidden ? panel.classList.add("hidden") : panel.classList.remove("hidden")
    })
  }

  setTagFooter(tagListTarget, footerTarget, editable, current) {
    tagListTarget.querySelectorAll("span a").forEach(link => {
      editable && current ? link.classList.remove("hidden") : link.classList.add("hidden")
    })

    tagListTarget.querySelectorAll("span").forEach(button => {
      const oldLabel = current ? "label-default" : "label-success"
      const newLabel = current ? "label-success" : "label-default"

      this.updateClass(button, newLabel, oldLabel)
    })

    current ? this.updateClass(footerTarget, "panel-footer", "panel-body") : this.updateClass(footerTarget, "panel-body", "panel-footer")
  }

  updateClass(classTarget, newLabel, oldLabel) {
    classTarget.classList.add(newLabel)
    classTarget.classList.remove(oldLabel)
  }

  resetButtons(designatedNext, previousEdits) {
    this.adjustButtons(previousEdits, this.editButtons)
    this.adjustButtons([designatedNext], this.nextButtons)
  }

  adjustButtons(showButtons, buttonGroup) {
    buttonGroup.forEach(editButton => {
      if (showButtons.includes(editButton)) {
        editButton.classList.remove("hidden")
      } else {
        editButton.classList.add("hidden")
      }
    })
  }

  create(event) {
    // Verify the timeframe has been filled in
    if (this.archiveStartDateTarget.value === "" || this.archiveEndDateTarget.value === "") {
      alert("You must specify a timeframe for the archive")
      event.preventDefault()
    }
  }

  recordListTarget(type) {
    return type == "organization" ? this.organizationsListTarget : this.agentsListTarget
  }

  recordPanelTarget(type) {
    return type == "organization" ? this.organizationsPanelTarget : this.agentsPanelTarget
  }
}
