import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["multiSelect"]

  removeOption(e) {
    const valueToRemove = e.currentTarget.dataset.value

    Array.from(this.multiSelectTarget.options).forEach(option => {
      if (option.value === valueToRemove) {
        option.selected = false
      }
    })

    this.multiSelectTarget.dispatchEvent(new Event("change", { bubbles: true }))
  }
}
