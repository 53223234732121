import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["dialog", "backdrop", "panel"]

  static values = {
    open: { type: Boolean, default: false },
    autoOpen: { type: Boolean, default: false },
  }

  connect() {
    this.Id = this.element.id

    if (this.autoOpenValue) {
      this.open()
    }

    document.addEventListener("ui:dialog:open", this.handleOpenEvent)
    document.addEventListener("keydown", this.handleKeydown.bind(this))
  }

  disconnect(){
    document.removeEventListener("ui:dialog:open", this.handleOpenEvent)
    document.removeEventListener("keydown", this.handleKeydown.bind(this))
  }

  clickSafeZone() {
    console.error("clickSafeZone MUST BE DEFINED IN CHILD CONTROLLER")
  }

  clickAway(event) {
    // don't close if clicking on or within the panel
    const safeZone = this.clickSafeZone()

    if (!safeZone) return

    if (safeZone.contains(event.target)) return

    this.close()
  }

  open() {
    // if already open, skip
    if (this.openValue) return

    this.openValue = true

    this.showDialog()
    this.transitionBackdropIn()
    this.transitionPanelIn()
  }

  close() {
    // if already closed, skip
    if (!this.openValue) return

    this.openValue = false

    this.transitionBackdropOut()
    this.transitionPanelOut()
    this.hideDialog()
  }

  applyTransition(element, options) {
    if (this.openValue) {

      // Apply enter transition
      if (options.enter) {
        element.classList.add(...options.enter.split(" "))
      }

      if (options.enterFrom) {
        element.classList.add(...options.enterFrom.split(" "))
      }

      // force browser reflow
      element.offsetWidth

      if (options.enterFrom && options.enterTo) {
        element.classList.remove(...options.enterFrom.split(" "))
        element.classList.add(...options.enterTo.split(" "))
      }
    } else {

      // Apply close transition
      if (options.leave) {
        element.classList.add(...options.leave.split(" "))
      }

      if (options.leaveFrom) {
        element.classList.add(...options.leaveFrom.split(" "))
      }

      element.offsetWidth

      if (options.leaveFrom && options.leaveTo) {
        element.classList.remove(...options.leaveFrom.split(" "))
        element.classList.add(...options.leaveTo.split(" "))
      }
    }
  }

  handleKeydown(event) {
    if (event.key == "Escape" && this.openValue) {
      this.close()
    }
  }

  handleOpenEvent = (event) => {
    if (event.detail.id == this.Id) {
      this.open()
    }
  }

  showDialog() {
    this.dialogTarget.classList.remove("hidden")
  }

  transitionBackdropIn() {
    console.error("transitionBackdropIn MUST BE DEFINED IN CHILD CONTROLLER")
  }

  transitionBackdropOut() {
    console.error("transitionBackdropOut MUST BE DEFINED IN CHILD CONTROLLER")
  }

  transitionPanelIn() {
    console.error("transitionPanelIn MUST BE DEFINED IN CHILD CONTROLLER")
  }

  transitionPanelOut() {
    console.error("transitionPanelOut MUST BE DEFINED IN CHILD CONTROLLER")
  }

  hideDialog() {
    console.error("hideDialog MUST BE DEFINED IN CHILD CONTROLLER")
  }
}
