import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["esqlQueryTypeLabel", "queryText"]

  connect() {    
    this.update_toggle(this.queryTextTarget.value)

    $(this.queryTextTarget).on("keyup", (e) => {
      this.update_toggle(this.queryTextTarget.value)
    })
  }

  update(event) { 
    this.update_toggle(event.currentTarget.dataset.inputContent)
  }

  update_toggle(text_value) { 
    if (text_value.startsWith("from logs")) {
      this.esqlQueryTypeLabelTarget.classList.remove("hidden")
    } else { 
      this.esqlQueryTypeLabelTarget.classList.add("hidden")
    }
  }
}
