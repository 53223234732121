import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form", "type"]

  connect() {
    $("body").on("shown.bs.modal", ".modal", this.showFieldsForType.bind(this))

    this.showFieldsForType()
  }

  disconnect() {
    $("body").off("shown.bs.modal", ".modal", this.showFieldsForType.bind(this))
  }

  showFieldsForType() {
    if(!this.hasTypeTarget)
      return

    $("#new-containment-remediation-modal .parameters").hide()

    let value = this.type
    if (value === "") return

    $("#" + value).show()

    $("#new-containment-remediation-modal .parameters input").prop("disabled", true)
    $("#" + value + " input").prop("disabled", false)
  }

  get type() {
    return this.typeTarget.value.replace(/::/g, "-")
  }
}
