import { Controller } from "@hotwired/stimulus"
import ApexCharts from "apexcharts"
import { initFlowbite } from "flowbite"

export default class extends Controller {
  static targets = ["chart"]

  connect() {
    const chart = this.chartTarget
    const data = JSON.parse(this.data.get("data"))
    const labels = JSON.parse(this.data.get("labels"))
    const colors = JSON.parse(this.data.get("colors"))

    const options = {
      series: data,
      colors: colors,
      labels: labels,
      chart: {
        type: "pie",
        height: 240,
        width: "100%"
      },
      plotOptions: {
        pie: {
          labels: {
            show: true,
          },
          size: "100%",
          dataLabels: {
            offset: -25
          }
        },
      },
      dataLabels: {
        enabled: true,
        style: {
          fontFamily: "Inter, sans-serif"
        },
        formatter: function (_, opts) {
          return `${opts.w.globals.labels[opts.seriesIndex]}: ${opts.w.globals.series[opts.seriesIndex]}`
        }
      },
      legend: {
        position: "bottom",
        fontFamily: "Inter, sans-serif",
      },
    }

    new ApexCharts(chart, options).render()
    initFlowbite()
  }
}
