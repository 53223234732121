import ApexCharts from "apexcharts"
import { initFlowbite } from "flowbite"
import ChartController from "./chart_controller"

export default class extends ChartController {
  static targets = ["chart", "counter", "dropdown"]

  connect() {
    const chart = this.chartTarget
    const data = JSON.parse(this.data.get("data"))

    const options = {
      colors: ["#FB923C", "#EF4444", "#A25906"],
      chart: {
        height: "350px",
        type: "line",
        fontFamily: "Inter, sans-serif",
        dropShadow: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      tooltip: {
        enabled: true,
        x: {
          show: false,
        },
        y: {
          formatter: this.formatTooltip
        }
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 6,
        curve: "smooth"
      },
      grid: {
        show: true,
        strokeDashArray: 4,
        padding: {
          left: 20,
          right: 20,
          top: -26
        },
      },
      series: data,
      legend: {
        show: true,
        position: "top",
        formatter: (seriesName, opts) => {
          return this.formatLegend(seriesName, opts)
        },
        labels: {
          useSeriesColors: true
        },
        markers: {
          size: 0,
          customHTML: function() {
            return ""
          }
        },
      },
      xaxis: {
        labels: {
          show: true,
          style: {
            fontFamily: "Inter, sans-serif",
            cssClass: "text-xs font-normal fill-current text-gray-500 dark:text-gray-200"
          },
          hideOverlappingLabels: true,
          showDuplicates: false,
          datetimeUTC: true,
          format: "MMM dd ",
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        type: "datetime",
      },
      yaxis: {
        show: false,
      },
    }

    this.chartObj = new ApexCharts(chart, options)
    this.chartObj.render()

    // Default View
    this.setDateRange(7)
    initFlowbite()
  }
}
