import { Controller } from "@hotwired/stimulus"
import * as Routes from "routes"

export default class extends Controller {
  static targets = [
    "accountSelect2",
    "accountDetailsFrame"
  ]

  static values = {
    accountDetailsPath: String
  }

  connect() {
    this.initializeSelect2()
  }

  initializeSelect2() {
    const select2 = $(this.accountSelect2Target).select2({
      placeholder: "Select an account",
      theme: "bootstrap",
      allowClear: true,
      width: "100%",
      ajax: {
        url: Routes.select2_admin_accounts_path(),
        dataType: "json",
        delay: 150,
        processResults: this.processSelect2Results
      }
    })

    select2.on("select2:select", () => {
      const accountId = this.accountSelect2Target.value
      this.accountDetailsFrameTarget.src = `${this.accountDetailsPathValue}?account_id=${accountId}`
      this.accountDetailsFrameTarget.loading = "eager"
    })
  }

  processSelect2Results(data, params) {
    const items = data.results.map(item => {
      return { id: item.id, text: `${item.text} (id: ${item.id})` }
    })

    return {
      results: items,
      pagination: {
        more: (params.page * 10) < data.count_filtered
      }
    }
  }
}
