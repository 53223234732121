import { Controller } from "@hotwired/stimulus"
import ApexCharts from "apexcharts"
import colors from "tailwindcss/colors"

export default class extends Controller {

  static targets = [
    "donutChart"
  ]

  static values = {
    dataValues: { type: Array },
    colors: { type: Array },
    labels: { type: Array },
    legendIcon: { type: Array }
  }

  connect() {
    let iconValues = this.legendIconValue.map ((x) => function() {return x})
    let customLegendFormatter = (seriesName, opts) => {
      return `
        <div class="flex justify-between items-center w-full">
          <div class="legend-item-label ml-2">${seriesName}</div>
          <div class="legend-item-value min-w-[35px] text-center">${opts.w.globals.series[opts.seriesIndex]}</div>
        </div>
    `
    }

    this.chartOptions = {
      series: this.dataValuesValue,
      colors: this.colorsValue,
      chart: {
        height: "100%",
        width: "100%",
        type: "donut",
      },
      stroke: {
        colors: ["white"],
        show: true,
        width: 2,
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              name: {
                offsetY: 20,
              },
              total: {
                show: true,
                label: "Total",
                color: colors.gray[500],
              },
              value: {
                color: colors.gray[500],
                offsetY: -20,
              },
            },
            size: "60%",
          },
        },
      },
      labels: this.labelsValue,
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        fillSeriesColor: false,
        custom: function({ series, seriesIndex, dataPointIndex, w }) {
          return (
            "<div class='ml-2 mr-1 flex flex-row items-center'><div class='icon-solid icon-before icon-circle' style='color:" + w.globals.colors[seriesIndex] + "'></div><span style='padding: 5px'>" +
            w.globals.labels[seriesIndex] +
            ": " +
            series[seriesIndex] +
            "</span></div>"
          )
        }
      },
      legend: {
        show: true,
        formatter: (seriesName, opts) => {
          return customLegendFormatter(seriesName, opts)
        },
        itemMargin: {
          horizontal: 0,
          vertical: 4,
        },
        markers: {
          size: 8,
          customHTML: iconValues
        },
      },
    }

    this.chart = new ApexCharts(this.donutChartTarget, this.chartOptions)
    this.chart.render()
  }

  disconnect() {
    this.chart.destroy()
  }
}
